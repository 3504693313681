<template>
    <div>
      <template v-for="menu in this.menuData">
        <el-submenu :key="menu.id" :index="menu.id" v-if="menu.children.length > 0">
            <template slot="title">              
                <h3 v-if="menu.level==1"><span slot="title">{{menu.title}}</span></h3>
                <span slot="title" v-else>{{menu.title}}</span>
            </template>
            <menu-tree :menuData="menu.children"></menu-tree>
        </el-submenu>
        <el-menu-item :key="menu.id" :index="menu.id" v-else>
          <span slot="title">{{menu.title}}</span>
        </el-menu-item>
      </template>
    </div>
  </template>
   
  <script>
  export default {
    props: ['menuData'],
    name: 'MenuTree'
  }
  
  </script>