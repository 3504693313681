import jsrsasign from "jsrsasign";

/**
 * pkcs8私钥
 */
var pkcs8_prikey = "MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCz9EvVT3pfhQA20SSG/8EN6whCudVqvi6PSAx90FUnrxlre53DQKE82Gd56+Qplhal7dfqnW7J68C5AC+gh1ng8Q45jsvZdMkYmHKap6XGL5DdJ8zjkAoVrz/r/vy+ygLCGWIkld/hrwqotI7GcASviFnfdrzYMTaPnSJ+mshBjzVAG2DNnABFlCjaTkBfhEKAXefkx1XMpwH35bPW4f0gIPdUAN/lwSXEBdM3frhNaO7GYf1F37ORGrzqgSZurotmaOTSoeeXQ8ycRuCdm0yaHbnn5jEJ00tnN9aUi2DeI4s7iKAmYFydULWECWita/siLl9wpkV89gflesTbI7PpAgMBAAECggEAZeMhtIqlbMOvFVpg5haURb8XFECHd0JqtjFC8VEthaR8LgD1mAFVyU91dvB6ZJlYBWae43VzrtvOaHCUhr1bxCJ3vVr+TAb1fPmDmdDl6UoxKSZJuWZqb1CkTog2rgdOfHtGuBQriGpFDLP+SphN7tyylfVygq2KiNGdIDWewI7TmdvECJN7UqdvhovoDNZBCInx+nbCLlK9QKI/OsUbA+7gMg2hY6sYAhas5EHLgo0fa3HBScZN/Zo4yr8J6mZi64wC91OWSOBHoiQ52DsYWXpAr5/eZrSp4q8eHpJobV9Y/xzJKA74PWcfa8ISayY7RQakVthVJ3zSqm5QR0GIAQKBgQDdg6ALdi1N03JWfa/8JWqRvLt0I2hFrwCWZjmgtepJFGioO8syWxyvIZcTN1G2DaK/FLmEQgPr8WVhOQZhdMHuT89jbJwkoTMGhyIGozJS2KsjV3hNBhx9IThj62pkRo2kADxTB3M+fypC7iNsx64wc3HfZGl4Bo6TXXKje6CBaQKBgQDP+FDLPucBVG7ULuwSMGz205esEDzFC6oe/iTkxfOe2CuUQlLEwVJhJ/tSBZDzl8oJRowq1kyfu8zhrpVFe9jYaFyqSZgb7fK+wLaMQ1BXdL0ZDj0H28nJxBDpN8Wpn9owQ011XVlSaSmE2TI7capf5w/HAJLEG8bpLHIVKiDOgQKBgB7ZYOWXvLF0/Bxtfp/H+jJ3rUd8Ut45hARuEiJ+kzVAQV8EjMTjiXgeXnLPpnKLLZoc4cU4iGFIigzjKy9RWly9Aq3fB1PWm0uScDuqaKy+/f57BQb9DeSjRr4rn/J9HOa1HbGWfQc8IH+XuJlCqdCXk0lAK6wKhbqiLf/0jKLZAoGAR2wE+zf4B4niofYXVsvQsBfMr+fHpvgxjYFEVFjzKKselEUOvcmSrBEaC3oj5LRNS1AyFQgRuo25eAwH8ZvtxaxZnpW1qmnWgtIWHyeC8PYDdxZfuVIvA57VG/LubCmcuhRzBopvgZDiFGCvEqEfnN3ymipFPRo4/p0n9MLKD4ECgYEAjHn0+m4k7hQPfubsg5o6jzx8cLPvtw2x0NthJaBVRX21fBA3yQepw05QRn0aJI/x/7utetcFcDqGI9D0u5MWe8HjbfyeLysF5UcrsWI+q0FluX1H3425fUqFwTPf51hbwT4mv40lGaJxVobnHFSCPpXQd/BXJkfT+sGV1A+sOlQ=";

/**
 * 随机字符串
 * @param {} length 
 */
function randomStr(length) {
    let str = '';
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    str += Date.now().toString(36);
    return str.slice(0, length);
}

/**
 * 签名生成
 * @param {} timestamp 
 * @param {*} nonce 
 * @param {*} options 
 */
function CreateSign(timestamp, nonce, config) {
    // console.log('请求拦截器==》：', config)
    var body = "";
    if (config.method == "get") {
        if (config.params && config.params != undefined) {
            for (let p in config.params) {
                if (body == "") {
                    body += p + "=" + encodeURIComponent(config.params[p])
                } else {
                    body += "&" + p + "=" + encodeURIComponent(config.params[p])
                }
            }
        }
    } else {
        body = JSON.stringify(config.data || "")
    }
    // console.log('body============>',body)
    // console.log('nonce============>',nonce)
    // console.log('timestamp============>',timestamp)

    const sig = new jsrsasign.KJUR.crypto.Signature({ "alg": "SHA256withRSA" });
    const rsa = jsrsasign.KEYUTIL.getKey("-----BEGIN PRIVATE KEY-----" + pkcs8_prikey + "-----END PRIVATE KEY-----")
    sig.init(rsa)

    sig.updateString(timestamp + nonce + body);
    var sign = jsrsasign.hextob64(sig.sign())
    // console.log('sign============>', sign)
    return sign;
}

export default {
    randomStr,
    CreateSign
}  