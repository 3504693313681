<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>资产统计</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/accountTotal?platform=' + platform }">资产</el-breadcrumb-item>
      <el-breadcrumb-item>{{ storeName }}{{ accountName }}流水</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="20">
        <!-- :gutter="20" 表示列直接间距20 -->
        <el-col :span="24">
          <span>时间：</span>
          <el-date-picker v-model="queryInfo.queryDate" type="daterange" align="right" unlink-panels range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
            @change="conditionChange">
          </el-date-picker>&nbsp;
          <el-button type="primary" @click="getData()">查询</el-button>
        </el-col>
      </el-row>
      <el-table :data="data" row-key="id" border default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column prop="account_flow_id" label="流水号"></el-table-column>
        <el-table-column label="金额" width="100">
          <template slot-scope="scope">
            {{ formatAmount(scope.row.amount) }}
          </template>
        </el-table-column>
        <el-table-column prop="currency_name" label="币种" width="100"></el-table-column>
        <el-table-column prop="created_time" label="时间"></el-table-column>
        <!-- <el-table-column prop="out_trans_no" label="业务单号"></el-table-column> -->
        <el-table-column label="业务单">
          <template slot-scope="scope">
            {{ scope.row.out_trans_no.startsWith("A") ? "交易单" : "" }}
            {{ scope.row.out_trans_no }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column width="130">
          <template slot-scope="scope">
            <el-button type="warning" size="mini" @click="showDialog(scope.row.out_trans_no)"
              icon="el-icon-view">查看业务单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pageIndex" :page-sizes="[18, 50, 100]" :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="pageInfo.total"></el-pagination>
    </el-card>
    <el-dialog :title="exchangeInfo.trans_category_type_name" :visible.sync="exchangeDialogVisible" width="50%" center>
      <!-- 内容主体 -->
      <div style="display: flex; justify-content: space-between">
        <span>单号：{{ exchangeInfo.business_id }}</span><span>制单人：{{ exchangeInfo.create_order_person
          }}</span><span>制单时间：{{
        exchangeInfo.created_time }}</span>
      </div>
      <div style="display: flex; justify-content: space-between">
        <span>制单店铺：{{ exchangeInfo.store_name }}</span>
        <span>客户：{{ exchangeInfo.customer_name }}({{
        exchangeInfo.customer_file_id
      }})</span><span>客户电话：{{ exchangeInfo.customer_phone_number }}</span>
      </div>
      <div style="display: flex; justify-content: space-between">
        <span v-if="exchangeInfo.bring_amount">自带：{{ formatAmount(exchangeInfo.bring_amount) }}{{
        exchangeInfo.buy_currency_name }}</span>
        <span v-if="exchangeInfo.taking_amount">自取：{{ formatAmount(exchangeInfo.taking_amount) }}{{
        exchangeInfo.buy_currency_name }}</span>
        <span v-if="exchangeInfo.transfer_fee">手续费：{{ formatAmount(exchangeInfo.transfer_fee) }}{{
        exchangeInfo.transfer_fee_currency_name }}</span>
      </div>
      <div style="display: flex; justify-content: space-between">
        <span>买入金额：{{ formatAmount(exchangeInfo.buy_amount) }}{{ exchangeInfo.buy_currency_name }}</span>
        <span>买汇价：{{ formatRate(exchangeInfo.exchange_rate) }}</span>
        <span>应付：{{ formatAmount(exchangeInfo.sale_amount) }}{{ exchangeInfo.sale_currency_name }}</span>
      </div>
      <el-divider content-position="left"></el-divider>
      <div v-for="item in exchangeInfo.details" :key="item.id">
        <div style="display: flex; justify-content: space-between">
          <div style="width: 70%; margin-right: 10px">
            <div v-for="subItem in item.childrens" :key="subItem.id" style="
                  display: flex;
                  justify-content: space-between;
                  widows: 100%;
                ">
              <span v-if="subItem.bank_or_other_code && subItem.bank_or_other_code.toUpperCase() === 'WX'"
                style="display: flex; align-items: center">
                <span>{{ subItem.bank_or_other_name }}收款码：</span>
                <vue-qr :text="subItem.payment_code" :size="150" :margin="1" logoSrc="img/wechat.png"
                  :correctLevel="3"></vue-qr>
                <span>{{ subItem.bank_or_other_name }}好友码：</span>
                <vue-qr :text="subItem.friend_qr_code" :size="150" :margin="1" logoSrc="img/wechat.png"
                  :correctLevel="3"></vue-qr>
              </span>
              <span v-else-if="subItem.bank_or_other_code && subItem.bank_or_other_code.toUpperCase() === 'ALIPAY'"
                style="display: flex; align-items: center">
                <span>{{ subItem.bank_or_other_name }}收款码：</span>
                <vue-qr :text="subItem.payment_code" :size="150" :margin="1" logoSrc="img/alipay.png"
                  :correctLevel="3"></vue-qr>
                <span>{{ subItem.bank_or_other_name }}好友码：</span>
                <vue-qr :text="subItem.friend_qr_code" :size="150" :margin="1" logoSrc="img/alipay.png"
                  :correctLevel="3"></vue-qr>
              </span>
              <span v-else-if="subItem.bank_or_other_code && subItem.bank_or_other_code.toUpperCase() === 'VM'" style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  ">
                <span>{{ exchangeInfo.sale_currency_code }}-{{
        subItem.friend_qr_code
      }}</span>
                <vue-qr :text="subItem.payment_code" :size="150" :margin="1" :correctLevel="3"></vue-qr>
              </span>
              <span v-else style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  ">
                <span>持卡人：{{ subItem.holder_name }}</span>
                <span>卡号：{{ formatBankCardNumber(subItem.payment_code) }}</span>
                <span>银行：{{ subItem.bank_or_other_name }}</span>
                <span>开户地：{{ subItem.account_location }}</span>
              </span>
            </div>
          </div>
          <div style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 30%;
              ">
            <span style="text-align: end">金额：{{ formatAmount(item.amount)
              }}{{ item.currency_name }}</span>
          </div>
        </div>
        <el-divider content-position="left"></el-divider>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="exchangeDialogVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import AmountTool from '../../util/AmountTool';
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      //传入参数
      accountName: "",
      storeName: "",

      //日期快捷键
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      //查询条件
      queryInfo: {
        accountId: "",
        pageIndex: 1,
        pageSize: 18,
        queryDate: "",
      },

      pageInfo: {
        total: 0,
      },
      data: [],

      exchangeDialogVisible: false,
      exchangeInfo: "",
    };
  },
  created() {
    // 接收参数
    this.queryInfo.accountId = this.$route.query.accountId;
    this.accountName = this.$route.query.accountName;
    this.storeName = this.$route.query.storeName;
    this.platform = this.$route.query.platform;
    this.getData();
  },
  methods: {
    async getData() {
      console.log(
        "时间=====>",
        this.queryInfo.queryDate === null || this.queryInfo.queryDate === ""
          ? "空的"
          : this.queryInfo.queryDate[0]
      );

      const { data: res } = await this.$http.get(
        "platform-api/cash940/Total/GetAccountFlow",
        {
          params: {
            accountId: this.queryInfo.accountId,
            pageIndex: this.queryInfo.pageIndex,
            pageSize: this.queryInfo.pageSize,
            startTime:
              this.queryInfo.queryDate === null ||
                this.queryInfo.queryDate === ""
                ? "null"
                : this.queryInfo.queryDate[0],
            endTime:
              this.queryInfo.queryDate === null ||
                this.queryInfo.queryDate === ""
                ? "null"
                : this.queryInfo.queryDate[1],
          },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);

      console.log("流水=====>", res.data);
      this.data = res.data.list;
      this.pageInfo = res.data.pageInfo;
    },
    //业务单详情
    async showDialog(businessId) {
      if (businessId.startsWith("A")) {
        const { data: res } = await this.$http.get(
          "platform-api/cash940/Total/ExchangeDetail",
          {
            params: {
              businessId: businessId,
            },
          }
        );
        if (res.code == -1) return this.$message.error(res.message);
        if (res.data == undefined) return this.$message.error("未找到业务单，或已删除");
        console.log("业务单详情=====>", res.data);

        //单据类型
        if (res.data.trans_category_type.toUpperCase() === "TS_CASH2CASH") {
          res.data.trans_category_type_name = "现金对现金交易单"
        }
        else if (res.data.trans_category_type.toUpperCase() === "TS_PRE2CASH") {
          res.data.trans_category_type_name = "余额对现金交易单"
        }
        else if (res.data.trans_category_type.toUpperCase() === "TS_CASH2REMITTANCE" || res.data.trans_category_type.toUpperCase() === "TS_PRE2REMITTANCE") {
          if (res.data.trans_category_type.toUpperCase() === "TS_CASH2REMITTANCE") {
            res.data.trans_category_type_name = "现金对代付交易单"
          }
          else {
            res.data.trans_category_type_name = "余额对代付交易单"
          }

          // 把或关系的组装到一个数组
          let details = [];
          if (res.data && res.data.exchangeDetails) {
            res.data.exchangeDetails.forEach((element) => {
              if (details.length === 0) {
                let childrens = [];
                childrens.push(element);
                details.push({
                  payment_or_flag: element.payment_or_flag,
                  amount: element.amount,
                  currency_name: element.currency_name,
                  childrens: childrens,
                });
              } else {
                let exists = false;
                details.forEach((v) => {
                  if (element.payment_or_flag === v.payment_or_flag) {
                    v.childrens.push(element);
                    exists = true;
                  }
                });
                if (exists === false) {
                  let childrens = [];
                  childrens.push(element);
                  details.push({
                    payment_or_flag: element.payment_or_flag,
                    amount: element.amount,
                    currency_name: element.currency_name,
                    childrens: childrens,
                  });
                }
              }
            });
          }

          res.data.details = details;
        }

        this.exchangeInfo = res.data;
        this.exchangeDialogVisible = true;
      } else {
        this.$message.error("待开发的单据类型详情展示");
      }
    },
    //查询条件变化
    conditionChange() {
      this.queryInfo.pageIndex = 1;
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize;
      this.getData();
    },
    //金额格式化
    formatAmount(amount) {
      return AmountTool.formatAmount(amount);
    },
    formatRate(rate) {
      return AmountTool.formatRate(rate);
    },
    //银行卡号格式化
    formatBankCardNumber(bankCardNumber) {
      // 去除空格并转换为字符串类型
      bankCardNumber = String(bankCardNumber).replace(/ /g, "");

      if (bankCardNumber.length < 16 || bankCardNumber.length > 20) {
        return bankCardNumber;
      } else {
        var formattedNumber = "";

        for (var i = 0; i < bankCardNumber.length; i++) {
          formattedNumber += bankCardNumber[i];

          // 每四位添加一个空格分隔
          if ((i + 1) % 4 === 0 && i !== bankCardNumber.length - 1) {
            formattedNumber += " ";
          }
        }

        return formattedNumber;
      }
    },
  },
};
</script>

<style lang='less' scoped></style>