import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/LoginView.vue";
import Home from "../views/HomeView.vue";
import SetPwd from "../views/SetPwdView.vue";
import ceSiZuJian from "../views/ceSiZuJian.vue";
import HelpView from "../views/HelpView.vue";
import Cash940Account from "../views/AccountTotal.vue";
import menuManage from "../views/menuManagement/index.vue";
import roleManage from "../views/roleManage/roleManage.vue";
import UserManage from "../views/UserManage.vue";
import Cash940AccountFlow from "../views/total/Cash940-Account-Flow.vue";
import TradeCenterAccountFlow from "../views/total/Trade-Center-Account-Flow.vue";
import CurrencyManage from "../views/CurrencyManagement/index.vue";
import InternationalNumber from "../views/InternationalNumber/index.vue";
import UserLoginLog from "../views/User-Login-Log.vue";
import SetUpFile from "../views/SetUpFile.vue";
import AppMenu from "../views/AppMenu.vue";
import RateManage from "../views/RateManage.vue";
import TenantRole from "../views/TenantRole.vue";
import ExchangeList from "../views/exchange/ExchangeList.vue";
import StoreAccountList from "../views/storeAccount/StoreAccountList.vue"

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/login" },
  { path: "/login", component: Login },
  { path: "/help", component: HelpView },
  {
    path: "/home",
    component: Home,
    // redirect: "/accountTotal",
    children: [
      { path: "/setpwd", component: SetPwd },
      { path: "/ceSiZuJian", component: ceSiZuJian },
      { path: "/accountTotal", component: Cash940Account },
      { path: "/menu-manage", component: menuManage },
      { path: "/role-manage", component: roleManage },
      { path: "/user-manage", component: UserManage },
      { path: "/accountFlow", component: Cash940AccountFlow },
      { path: "/center-accountFlow", component: TradeCenterAccountFlow },
      { path: "/currency-manage", component: CurrencyManage },
      { path: "/international-number", component: InternationalNumber },
      { path: "/user-login-log", component: UserLoginLog },
      { path: "/setupfile", component: SetUpFile },
      { path: "/app-menus", component: AppMenu },
      { path: "/rate-manage", component: RateManage },
      { path: "/tenants", component: RateManage },
      { path: "/tenant-user", component: RateManage },
      { path: "/tenant-role", component: TenantRole },
      { path: "/store-exchange", component: ExchangeList },
      { path: "/store-account", component: StoreAccountList },
    ],
  },
];

const router = new VueRouter({
  routes,
  base: "platform",
});

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
  // 访问登录页，放行
  if (to.path === "/login") return next();
  // 获取token
  // const tokenStr = window.sessionStorage.getItem('token')
  // 没有token, 强制跳转到登录页
  // if (!tokenStr) return next('/login')
  next();
});

export default router;
