
<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>管理设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user-manage' }">用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>登录日志</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <el-table :data="data" border :row-class-name="RowClass">
        <el-table-column prop="nick" label="登录用户昵称"></el-table-column>        
        <el-table-column prop="login_result" label="登录结果"></el-table-column>
        <el-table-column prop="login_type" label="登录类型"></el-table-column>
        <el-table-column prop="login_time" label="登录时间"></el-table-column>
        <el-table-column prop="ip" label="登录IP"></el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageIndex"
        :page-sizes="[18, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
      ></el-pagination>
    </el-card>
  </div>
</template>
      
      <script>
export default {
  data() {
    return {
      //查询条件
      queryInfo: {
        userId: "",
        pageIndex:1,
        pageSize:18
      },

      pageInfo: {
        total: 0,
      },
      data: [],
    };
  },
  created() {
    // 接收参数
    this.queryInfo.userId = this.$route.query.user_id;

    this.getData();
  },
  methods: {
    async getData() {
      console.log("查询条件", this.queryInfo);
      const { data: res } = await this.$http.get(
        "platform-auth/user/GetUserLoginLog",
        {
          params: this.queryInfo,
        }
      );
      if (res.code == -1) return this.$message.error(res.message);

      console.log("登录日志=====>", res.data);
      this.data = res.data;
      this.pageInfo = res.pageInfo;
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize;
      this.getData();
    },
    //行样式
    RowClass({ rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "s_s";
      } else {
        return "t_s";
      }
    },
  },
};
</script>
      
      <style lang='less' scoped>
.el-table {
  /deep/ .s_s {
    background: #228b22;
    color: white;
  }

  /deep/ .t_s {
    background: #6b8e23;
    color: white;
  }

  // 鼠标悬浮时样式
  /deep/ .el-table__body tr:hover > td {
    background-color: #3c3c3c !important;
    color: goldenrod !important;
    font-size: 16px;
  }
}
</style>