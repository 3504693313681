<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础数据</el-breadcrumb-item>
      <el-breadcrumb-item>币种管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <div style="display: flex;justify-content: flex-start;align-items: center;">
        <el-checkbox v-model="queryInfo.currency_type" @change="query">虚拟币</el-checkbox>
        <el-checkbox v-model="queryInfo.is_create_store_use" @change="query">初始化币种</el-checkbox>
        <el-input placeholder="请输入币种名称或代码" v-model="queryInfo.condition" clearable @clear="query"
          style="width: 250px;margin-left: 10px;">
          <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
        </el-input>&nbsp;&nbsp;
        <el-button type="primary" @click="showAddDialog">添加</el-button>
        <el-button type="primary" @click="removeSelected">删除选择</el-button>
      </div>

      <el-table :data="data" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>        
        <el-table-column prop="currency_id" label="币种编号"></el-table-column>
        <el-table-column prop="currency_code" label="币种代码"></el-table-column>
        <el-table-column prop="currency_name" label="币种名称"></el-table-column>
        <el-table-column prop="currency_symbol" label="符号"></el-table-column>
        <el-table-column label="币种类型">
          <template slot-scope="scope">
            <span v-if="scope.row.currency_type == 1">法币</span>
            <span v-if="scope.row.currency_type == 2">虚拟币</span>
          </template>
        </el-table-column>
        <el-table-column label="店铺初始化币种">
          <template slot-scope="scope">
            <span v-if="scope.row.is_create_store_use == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column prop="sort_number" label="顺序号"></el-table-column>

        <el-table-column width="180">
          <template slot-scope="scope">
            <el-button type="warning" size="mini" @click="showEditDialog(scope.row)" icon="el-icon-edit">编辑
            </el-button>
            <el-button type="danger" size="mini" @click="deleteData(scope.row)" icon="el-icon-delete">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pageIndex" :page-sizes="[60, 80, 100]" :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <el-dialog title="添加" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="120px" size="small">
        <el-form-item label="币种编号" prop="currency_id">
          <el-input v-model="addForm.currency_id"></el-input>
        </el-form-item>
        <el-form-item label="币种代码" prop="currency_code">
          <el-input v-model="addForm.currency_code"></el-input>
        </el-form-item>
        <el-form-item label="币种名称" prop="currency_name">
          <el-input v-model="addForm.currency_name"></el-input>
        </el-form-item>
        <el-form-item label="符号" prop="currency_symbol">
          <el-input v-model="addForm.currency_symbol"></el-input>
        </el-form-item>
        <el-form-item label="顺序号" prop="sort_number">
          <el-input v-model="addForm.sort_number"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="currency_type">
          <el-select v-model="addForm.currency_type" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺初始化币种" prop="is_create_store_use">
          <el-checkbox v-model="addForm.is_create_store_use"></el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
      <!-- 内容主体 -->
      <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" label-width="120px" size="small">
        <el-form-item label="币种代码" prop="currency_code">
          <el-input v-model="editForm.currency_code"></el-input>
        </el-form-item>
        <el-form-item label="币种名称" prop="currency_name">
          <el-input v-model="editForm.currency_name"></el-input>
        </el-form-item>
        <el-form-item label="符号" prop="currency_symbol">
          <el-input v-model="editForm.currency_symbol"></el-input>
        </el-form-item>
        <el-form-item label="顺序号" prop="sort_number">
          <el-input v-model="editForm.sort_number"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="currency_type">
          <el-select v-model="editForm.currency_type" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺初始化币种" prop="is_create_store_use">
          <el-checkbox v-model="editForm.is_create_store_use"></el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "currencyManage",
  data() {
    return {
      options: [{
        value: 1,
        label: '法币'
      }, {
        value: 2,
        label: '虚拟币'
      }],
      //多选数据
      multipleSelection: [],
      //查询条件
      queryInfo: {
        // 选中的部门结点，用来过滤数据
        condition: "",
        currency_type: false,
        is_create_store_use: false,
        // 当前页数
        pageIndex: 1,
        // 每页显示多少数据
        pageSize: 60
      },
      total: 0,
      // 获取的列表数据
      data: [],

      addDialogVisible: false,
      // 上级添加
      addForm: {
        currency_id:"",
        currency_code: '',
        currency_name: '',
        currency_symbol: "",
        sort_number: '',
        currency_type:1,
        is_create_store_use:false
      },
      addFormRules: {
        currency_id: [
          { required: true, message: '请输入编号', trigger: 'blur' },
        ],
        currency_code: [
          { required: true, message: '请输入代码', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '长度在2～10个字',
            trigger: 'blur'
          }
        ],
        currency_name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          {
            min: 2,
            max: 50,
            message: '长度在2～50个字',
            trigger: 'blur'
          }
        ],
      },

      editDialogVisible: false,
      editForm: {
      },
      editFormRules: {
        currency_code: [
          { required: true, message: '请输入代码', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '长度在2～10个字',
            trigger: 'blur'
          }
        ],
        currency_name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          {
            min: 2,
            max: 50,
            message: '长度在2～50个字',
            trigger: 'blur'
          }
        ],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const { data: res } = await this.$http.get("platform-foundation/api/currency", {
        params: this.queryInfo
      });
      if (res.code == -1) return this.$message.error(res.message);
      console.log(res.data)

      this.data = res.data.list;
      this.total = res.data.pageInfo.total;
    },
    query() {
      this.queryInfo.pageIndex = 1
      this.getData()
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    showAddDialog() {
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },
    //添加
    async add() {
      // 提交请求前，表单预验证
      this.$refs.addFormRef.validate(async valid => {
        // 表单预校验失败
        if (!valid) return

        if (this.addForm.is_create_store_use == true) {
          this.addForm.is_create_store_use = 1
        }
        else {
          this.addForm.is_create_store_use = 0
        }
        console.log("参数",this.addForm)
        const { data: res } = await this.$http.post('platform-foundation/api/currency', this.addForm)
        if (res.code == -1) return this.$message.error(res.message);
        this.$message.success('添加成功！')
        // 隐藏添加对话框
        this.addDialogVisible = false
        this.getData()
      })
    },
    async showEditDialog(row) {
      console.log("编辑数据", row);
      if (row.is_zs == 1) {
        row.is_zs = true;
      } else {
        row.is_zs = false;
      }
      this.editForm = JSON.parse(JSON.stringify(row))
      this.editDialogVisible = true
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    //修改
    async edit() {
      // 提交请求前，表单预验证
      this.$refs.editFormRef.validate(async valid => {
        // 表单预校验失败
        if (!valid) return

        console.log("参数",this.editForm)
        const { data: res } = await this.$http.put('platform-foundation/api/currency', this.editForm)
        if (res.code == -1) return this.$message.error(res.message);
        // 隐藏编辑对话框
        this.editDialogVisible = false
        this.$message.success('修改成功！')
        this.getData()
      })
    },
    //删除
    async deleteData(row) {
      const confirmResult = await this.$confirm(
        '此操作将永久删除, 是否继续?',
        '提示',
        {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult == 'confirm') {
        const ids = []
        ids.push(row.currency_id)

        console.log("删除项", ids)
        const { data: res } = await this.$http.delete('platform-foundation/api/currency', {
          data: ids
        })
        if (res.code == -1) return this.$message.error(res.message);
        this.$message.success('删除成功！')
        this.getData()
      }
    },
    // 批量删除
    async removeSelected() {
      if (this.multipleSelection.length == 0) return;

      console.log(this.multipleSelection)
      const ids = []
      this.multipleSelection.forEach(v => {
        ids.push(v.currency_id)
      })

      const confirmResult = await this.$confirm(
        '此操作将永久删除选定项目, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult == 'confirm') {
        console.log("删除项", ids)
        const { data: res } = await this.$http.delete('platform-foundation/api/currency',
          {
            data: ids
          })
        if (res.code == -1) return this.$message.error(res.message)
        this.$message.success('删除成功！')
        this.getData()
      }
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getData()
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize
      this.getData()
    },
  },
};
</script>

<style lang='less' scoped>
</style>