<template>
    <div class="menuManage">
      <div class="headerBtn">
        <el-button type="primary" @click="addMenu">添加菜单</el-button>
      </div>
      <!-- <div class="search">
        <el-input v-model="menuValue" placeholder="菜单名称"></el-input>
        <el-button type="primary" @click="search">搜索</el-button>
      </div> -->
      <div class="tableBox">
        <el-table
          :data="menuData"
          style="width: 100%; margin-bottom: 20px"
          row-key="menu_code"
          border
          default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column prop="menu_name" label="名称">
            <template slot-scope="scope">
              <span>{{scope.row.menu_name}}</span>&nbsp;
              <i :class="scope.row.icon"></i>            
            </template>
          </el-table-column>
          <el-table-column prop="menu_order" label="序号"> </el-table-column>
          <el-table-column prop="des" label="说明"> </el-table-column>
          <el-table-column prop="menu_code" label="编码" class="menu_code"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog
        :title="menuTitle"
        :visible.sync="addMenuShow"
        width="30%"
        center
      >
        <div class="menuFrom">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="编码" prop="menu_code">
              <el-input v-model="ruleForm.menu_code"></el-input>
            </el-form-item>
            <el-form-item label="名称" prop="menu_name">
              <el-input v-model="ruleForm.menu_name"></el-input>
            </el-form-item>
            <el-form-item label="父级" prop="parent_code">
              <el-cascader
                v-model="ruleForm.parent_code"
                :options="menuData"
                placeholder="请选择父级"
                popper-class="select-cascader"
                clearable
                :props="{
                  expandTrigger: 'hover',
                  checkStrictly: true,
                  label: 'menu_name',
                  value: 'menu_code',
                }"
                @change="changeParent"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="描述" prop="des">
              <el-input v-model="ruleForm.des"></el-input>
            </el-form-item>
            <el-form-item label="顺序" prop="menu_order">
              <el-input v-model="ruleForm.menu_order"></el-input>
            </el-form-item>
            <el-form-item label="图标" prop="icon">
              <el-input v-model="ruleForm.icon"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >提交</el-button
              >
              <el-button @click="resetForm('ruleForm')">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  export default {
    name: "menuManage",
    components: {},
    data() {
      return {
        menuTitle: "添加菜单",
        addMenuShow: false,
        menuValue: "",
        menuData: [],
        ruleForm: {
          menu_code: "",
          menu_name: "",
          parent_code: "",
          des: "",
          menu_order: "",
          icon: "",
        },
        rules: {
          menu_code: [{ required: true, message: "请输入编码", trigger: "blur" }],
          menu_name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        },
      };
    },
    computed: {},
    watch: {},
    methods: {
      /** 添加菜单 */
      addMenu() {
        this.menuTitle = "添加菜单";
        this.ruleForm = {
          menu_code: "",
          menu_name: "",
          parent_code: "",
          des: "",
          menu_order: "",
          icon: "",
        };
        this.addMenuShow = true;
      },
      /** 提交 */
      submitForm(formName) {
        let paramsJson = {
          menu_code: this.ruleForm.menu_code,
          menu_name: this.ruleForm.menu_name,
          parent_code: this.ruleForm.parent_code || "",
          menu_order: Number(this.ruleForm.menu_order) || "",
          icon: this.ruleForm.icon || "",
          des: this.ruleForm.des || "",
        };
  
        this.$refs[formName].validate(async (valid) => {
          if (!valid) return false;
          let res = "";
          if (this.menuTitle == "添加菜单") {
            res = await this.$http.post("platform-foundation/Menu", paramsJson);
          } else if (this.menuTitle == "修改菜单") {
            res = await this.$http.put("platform-foundation/Menu", paramsJson);
          }
          this.getMenuList();
          if (res.data.code == -1) return this.$message.error(res.data.message);
        });
        this.$refs[formName].resetFields();
        this.addMenuShow = false;
      },
      /** 取消 */
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.addMenuShow = false;
      },
      // 选择父级事件
      changeParent(value) {
        this.ruleForm.parent_code = value[value.length - 1];
      },
      /** 编辑 */
      handleEdit(index, row) {
        this.menuTitle = "修改菜单";
        this.ruleForm = {
          menu_code: row.menu_code || "",
          menu_name: row.menu_name || "",
          parent_code: row.parent_code || "",
          des: row.des || "",
          menu_order: row.menu_order || "",
          icon: row.icon || "",
        };
        this.addMenuShow = true;
        console.log(index, row);
      },
      /** 删除 */
      async handleDelete(index, row) {
        let params;
        if (!row.children) {
          params = [row.menu_code];
        } else if (row.children) {
          if (row.children.length > 0) {
            params = [row.menu_code];
            row.children.forEach((item) => {
              params.push(item.menu_code);
            });
          } else {
            params = [row.menu_code];
          }
        }
        const { data: res } = await this.$http.delete("platform-foundation/Menu", {
          data: params,
        });
        if (res.code == -1) return this.$message.error(res.message);
        this.getMenuList();
      },
      /** 请求菜单数据列表 */
      async getMenuList() {
        const { data: res } = await this.$http.get("platform-foundation/Menu");
        if (res.code == -1) return this.$message.error(res.message);
        console.log(res);
        // 重新组织数据
        const menusLevel1 = [];
        res.data.forEach((element) => {
          if (
            element.parent_code === null ||
            element.parent_code === "0" ||
            element.parent_code === undefined ||
            element.parent_code === ""
          ) {
            menusLevel1.push(element);
  
            const menusLevel2 = [];
            res.data.forEach((v) => {
              if (v.parent_code === element.menu_code) {
                menusLevel2.push(v);
              }
            });
            element.children = menusLevel2;
          }
        });
  
        this.menuData = menusLevel1; // res.list
      },
    },
    mounted() {
      this.getMenuList();
    },
  };
  </script>
  <style lang="less" scoped>
  .menuManage {
    .headerBtn {
      margin-bottom: 20px;
    }
    .search {
      display: flex;
      align-items: center;
    }
    .tableBox {
      padding: 20rpx;
      /deep/.cell {
        text-align: center !important;
      }
      /deep/.el-table__body-wrapper {
        .el-table_1_column_1 {
          .cell {
            text-align: left !important;
          }
        }
      }
    }
  }
  </style>
  