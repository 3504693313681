<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商户管理</el-breadcrumb-item>
      <el-breadcrumb-item>账户余额</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <div class="search-box">
        选择商家:
        <el-popover
          placement="bottom"
          width="200"
          v-model="tenantPopover"
          trigger="click"
        >
          <div class="popover-content">
            <div
              v-for="(item, index) in storeList"
              :key="index"
              @click="handleStoreChange(item)"
            >
              {{ item.tenant_name }}
            </div>
          </div>
          <el-button slot="reference">{{ tenantInfo.tenant_name }}</el-button>
        </el-popover>
      </div>
      <el-table :data="data" border>
        <el-table-column prop="store_name" label="店铺"></el-table-column>
        <el-table-column prop="store_account_name" label="账户">
        </el-table-column>
        <el-table-column label="余额">
          <template slot-scope="scope">
            <div v-for="item in scope.row.balances" :key="item.currency_id">
              <span
                >{{ formatAmount(item.balance) }}{{ item.currency_name }}</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //查询条件
      queryInfo: {
        // 当前页数
        pageIndex: 1,
        // 每页显示多少数据
        pageSize: 60,
      },
      // 获取的列表数据
      data: [],
      storeList: [],
      tenantInfo: {},
      tenantPopover: false,
    };
  },
  created() {
    this.getStoreList();
  },
  methods: {
    async getData() {
      const { data: res } = await this.$http.get(
        "platform-api/v1/StoreAccount",
        {
          params: {
            tenant: this.tenantInfo.tenant_id,
          },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      console.log(res.data);

      this.data = res.data;
    },
    // 获取商家列表
    async getStoreList() {
      const { data: res } = await this.$http.get("platform-api/v1/Tenant", {
        params: {},
      });
      if (res.code == -1) return this.$message.error(res.message);
      console.log(res);
      this.storeList = res.data;
      this.tenantInfo = res.data[0];
      this.getData();
    },
    // 商家选择
    handleStoreChange(item) {
      this.tenantInfo = item;
      this.queryInfo.pageIndex = 1;
      this.getData();
      this.tenantPopover = false;
    },
    query() {
      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize;
      this.getData();
    },
    async showEditDialog(row) {
      console.log("编辑数据", row);
      const { data: res } = await this.$http.get(
        "platform-api/v1/StoreExchange/GetDetail",
        {
          params: {
            ExchangeID: row.store_exchange_id,
          },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      console.log(res.data);
    },
    //金额格式化
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);

      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
  },
};
</script>

<style lang="less" scoped>
.search-box {
  display: flex;
  align-items: center;
  > span {
    margin-left: 10px;
  }
}
.popover-content {
  width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 20px 0;
  box-sizing: border-box;
  > div {
    width: 90%;
    height: 50px;
    box-sizing: border-box;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
  }
}
.el-table {
  /deep/ .s_s {
    background: #228b22;
    color: white;
  }

  /deep/ .t_s {
    background: #6b8e23;
    color: white;
  }
}
</style>
