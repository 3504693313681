<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础数据</el-breadcrumb-item>
      <el-breadcrumb-item>国际号码管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="20">
        <!-- :gutter="20" 表示列直接间距20 -->
        <el-col :span="6">
          <!-- :span="6" 表示列宽权重 24表示整行 -->
          <el-input placeholder="请输入国家地区名称" v-model="queryInfo.condition" clearable @clear="query">
            <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
          </el-input>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="showAddDialog">添加</el-button>
          <el-button type="primary" @click="removeSelected">删除选择</el-button>
        </el-col>
      </el-row>
      <el-table :data="data" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="sort_number" label="序号"></el-table-column>
        <el-table-column prop="country_code" label="国家地区代码"> </el-table-column>
        <el-table-column prop="country_name" label="国家地区名称"></el-table-column>
        <el-table-column prop="international_number" label="国际号码"></el-table-column>
        <el-table-column label="启用">
          <template slot-scope="scope">
            {{scope.row.status?'是':'否'}}
          </template>
        </el-table-column>
        <el-table-column width="180">
          <template slot-scope="scope">
            <el-button type="warning" size="mini" @click="showEditDialog(scope.row)" icon="el-icon-edit">编辑
            </el-button>
            <el-button type="danger" size="mini" @click="deleteData(scope.row)" icon="el-icon-delete">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pageIndex" :page-sizes="[60, 80, 100]" :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <el-dialog title="添加" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="120px" size="small">
        <el-form-item label="国家地区代码" prop="country_code">
          <el-input v-model="addForm.country_code"></el-input>
        </el-form-item>
        <el-form-item label="国家地区名称" prop="country_name">
          <el-input v-model="addForm.country_name"></el-input>
        </el-form-item>
        <el-form-item label="国际号码" prop="international_number">
          <el-input v-model="addForm.international_number"></el-input>
        </el-form-item>
        <el-form-item label="顺序号" prop="sort_number">
          <el-input v-model="addForm.sort_number"></el-input>
        </el-form-item>
        <el-form-item label="启用" prop="status">
          <el-checkbox v-model="addForm.status"></el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
      <!-- 内容主体 -->
      <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" label-width="120px" size="small">
        <el-form-item label="国家地区代码" prop="country_code">
          <el-input v-model="editForm.country_code"></el-input>
        </el-form-item>
        <el-form-item label="国家地区名称" prop="country_name">
          <el-input v-model="editForm.country_name"></el-input>
        </el-form-item>
        <el-form-item label="国际号码" prop="international_number">
          <el-input v-model="editForm.international_number"></el-input>
        </el-form-item>
        <el-form-item label="顺序号" prop="sort_number">
          <el-input v-model="editForm.sort_number"></el-input>
        </el-form-item>
        <el-form-item label="启用" prop="status">
          <el-checkbox v-model="editForm.status"></el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "international_number",
  data() {
    return {
      //多选数据
      multipleSelection: [],
      //查询条件
      queryInfo: {
        // 选中的部门结点，用来过滤数据
        condition: "",
        // 当前页数
        pageIndex: 1,
        // 每页显示多少数据
        pageSize: 60
      },
      total: 0,
      // 获取的列表数据
      data: [],

      addDialogVisible: false,
      // 上级添加
      addForm: {
        country_code: '',
        country_name: '',
        international_number: "",
        sort_number: 0,
        status:true
      },
      addFormRules: {
        country_code: [
          { required: true, message: '请输入代码', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '长度在2～10个字',
            trigger: 'blur'
          }
        ],
        country_name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          {
            min: 2,
            max: 50,
            message: '长度在2～50个字',
            trigger: 'blur'
          }
        ],
        international_number: [
          { required: true, message: '请输入号码', trigger: 'blur' },
          {
            min: 1,
            max: 10,
            message: '长度在1～10个字',
            trigger: 'blur'
          }
        ],
      },

      editDialogVisible: false,
      editForm: {
      },
      editFormRules: {
        country_code: [
          { required: true, message: '请输入代码', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '长度在2～10个字',
            trigger: 'blur'
          }
        ],
        country_name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          {
            min: 2,
            max: 50,
            message: '长度在2～50个字',
            trigger: 'blur'
          }
        ],
        international_number: [
          { required: true, message: '请输入号码', trigger: 'blur' },
          {
            min: 1,
            max: 10,
            message: '长度在1～10个字',
            trigger: 'blur'
          }
        ],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const { data: res } = await this.$http.get("platform-foundation/api/internationalnumber", {
        params: this.queryInfo
      });
      console.log("号码",res)
      if (res.code == -1) return this.$message.error(res.message);

      this.data = res.data.list;      
      this.total = res.data.pageInfo.total;
    },
    query() {
      this.queryInfo.pageIndex = 1
      this.getData()
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    showAddDialog() {
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },
    //添加
    async add() {
      // 提交请求前，表单预验证
      this.$refs.addFormRef.validate(async valid => {
        // 表单预校验失败
        if (!valid) return

        const { data: res } = await this.$http.post('platform-foundation/api/internationalnumber', this.addForm)
        if (res.code == -1) return this.$message.error(res.message);
        this.$message.success('添加成功！')
        // 隐藏添加对话框
        this.addDialogVisible = false
        this.getData()
      })
    },
    async showEditDialog(row) {
      console.log("编辑数据", row);
      if (row.is_zs == 1) {
        row.is_zs = true;
      } else {
        row.is_zs = false;
      }
      this.editForm = JSON.parse(JSON.stringify(row))
      this.editDialogVisible = true
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    //修改
    async edit() {
      // 提交请求前，表单预验证
      this.$refs.editFormRef.validate(async valid => {
        // 表单预校验失败
        if (!valid) return

        const { data: res } = await this.$http.put('platform-foundation/api/internationalnumber', this.editForm)
        if (res.code == -1) return this.$message.error(res.message);
        // 隐藏编辑对话框
        this.editDialogVisible = false
        this.$message.success('修改成功！')
        this.getData()
      })
    },
    //删除
    async deleteData(row) {
      const confirmResult = await this.$confirm(
        '此操作将永久删除, 是否继续?',
        '提示',
        {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult == 'confirm') {
        const ids = []
        ids.push(row.country_code)

        console.log("删除项", ids)
        const { data: res } = await this.$http.delete('platform-foundation/api/internationalnumber', {
          data: ids
        })
        if (res.code == -1) return this.$message.error(res.message);
        this.$message.success('删除成功！')
        this.getData()
      }
    },
    // 批量删除
    async removeSelected() {
      if (this.multipleSelection.length == 0) return;

      console.log(this.multipleSelection)
      const ids = []
      this.multipleSelection.forEach(v => {
        ids.push(v.country_code)
      })

      const confirmResult = await this.$confirm(
        '此操作将永久删除选定项目, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult == 'confirm') {
        console.log("删除项", ids)
        const { data: res } = await this.$http.delete('platform-foundation/api/internationalnumber',
          {
            data: ids
          })
        if (res.code == -1) return this.$message.error(res.message)
        this.$message.success('删除成功！')
        this.getData()
      }
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getData()
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize
      this.getData()
    },
  },
};
</script>

<style lang='less' scoped>
</style>