<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>管理设置</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-button type="primary" @click="showAddDialog">添加</el-button>
          <el-button type="primary" @click="removeSelected">删除选择</el-button>
        </el-col>
      </el-row>
      <el-table
        :data="data"
        border
        @selection-change="handleSelectionChange"
        :row-class-name="RowClass"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="account" label="登录账户"></el-table-column>
        <el-table-column prop="nick" label="昵称"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status =='enable'">启用</span>
            <span v-if="scope.row.status =='disable'">禁用</span>
            <span v-if="scope.row.status =='lock'">锁定</span>
          </template>
        </el-table-column>
        <el-table-column label="角色">
          <template slot-scope="scope">
            <span v-for="item in scope.row.userRoles" :key="item.role_id"
              >{{ item.role_name }}<br
            /></span>
          </template>
        </el-table-column>
        <el-table-column prop="create_user_name" label="创建人"></el-table-column>
        <el-table-column prop="create_ip" label="IP"></el-table-column>
        <el-table-column prop="create_time" label="创建时间"></el-table-column>
        <el-table-column width="300">
          <template slot-scope="scope">
            <el-button
              type="warning"
              size="mini"
              @click="showEditDialog(scope.row)"
              icon="el-icon-edit"
              >编辑
            </el-button>
            <el-button
              type="danger"
              size="mini"
              @click="deleteData(scope.row)"
              icon="el-icon-delete"
              >删除
            </el-button>
            <el-button
              type="warning"
              size="mini"
              @click="$router.push({
                  path: '/user-login-log',
                  query: {
                    user_id: scope.row.user_id,
                  },
                })"
              >登录日志
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageIndex"
        :page-sizes="[18, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
      ></el-pagination>
    </el-card>

    <el-dialog
      title="添加"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
      center
    >
      <el-form
        :model="addForm"
        ref="addFormRef"
        :rules="addFormRules"
        label-width="120px"
        size="small"
      >
        <el-form-item label="昵称" prop="nick">
          <el-input v-model="addForm.nick"></el-input>
        </el-form-item>
        <el-form-item label="登录账号" prop="account">
          <el-input v-model="addForm.account"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="pwd">
          <el-input v-model="addForm.pwd"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roles">
          <el-checkbox-group v-model="addForm.roles">
            <el-checkbox
              border
              :label="item.role_id"
              v-for="item in rolesOptions"
              :key="item.role_id"
              >{{ item.role_name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
      center
    >
      <el-form
        :model="editForm"
        ref="editFormRef"
        :rules="editFormRules"
        label-width="120px"
        size="small"
      >
        <el-form-item label="昵称" prop="nick">
          <el-input v-model="editForm.nick"></el-input>
        </el-form-item>
        <el-form-item label="登录账号" prop="account">
          <el-input v-model="editForm.account"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="account">
          <el-select v-model="editForm.status" placeholder="请选择">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录密码" prop="pwd">
          <el-input v-model="editForm.pwd"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roles">
          <el-checkbox-group v-model="editForm.roles">
            <el-checkbox
              :label="item.role_id"
              v-for="item in rolesOptions"
              :key="item.role_id"
              >{{ item.role_name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo:{
        pageIndex:1,
        pageSize:18
      },
      statusOptions: [
        {
          value: "enable",
          label: "启用",
        },
        {
          value: "disable",
          label: "禁用",
        },
      ],
      rolesOptions: [],
      //多选数据
      multipleSelection: [],
      // 获取的列表数据
      data: [],
      pageInfo:{
        total:0
      },

      addDialogVisible: false,
      // 上级添加
      addForm: {
        nick: "",
        account: "",
        pwd: "",
        roles: [],
      },
      addFormRules: {
        nick: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在2～50个字",
            trigger: "blur",
          },
        ],
      },

      editDialogVisible: false,
      editForm: {},
      editFormRules: {
        nick: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在2～50个字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getData();
    this.getRoleOptions();
  },
  methods: {
    async getData() {
      const { data: res } = await this.$http.get("platform-auth/user",
      {
        params: this.queryInfo
      });
      if (res.code == -1) return this.$message.error(res.message);
      console.log(res.data);

      this.data = res.data;
      this.pageInfo = res.pageInfo;
    },
    async getRoleOptions() {
      const { data: res } = await this.$http.get("platform-auth/api/role");
      this.rolesOptions = res.data;
      console.log(res);
    },
    query() {
      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    showAddDialog() {
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },
    //添加
    async add() {
      // 提交请求前，表单预验证
      this.$refs.addFormRef.validate(async (valid) => {
        // 表单预校验失败
        if (!valid) return;

        console.log("提交参数", this.addForm);

        const { data: res } = await this.$http.post(
          "platform-auth/user",
          this.addForm
        );
        if (res.code == -1) return this.$message.error(res.message);
        this.$message.success("添加成功！");
        // 隐藏添加对话框
        this.addDialogVisible = false;
        this.getData();
      });
    },
    async showEditDialog(row) {
      let editData = JSON.parse(JSON.stringify(row));

      let roles = [];
      editData.userRoles.forEach((v) => {
        roles.push(v.role_id);
      });
      editData.roles = roles;

      this.editForm = editData;

      this.editDialogVisible = true;
      console.log("编辑数据", this.editForm);
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    //修改
    async edit() {
      // 提交请求前，表单预验证
      this.$refs.editFormRef.validate(async (valid) => {
        // 表单预校验失败
        if (!valid) return;

        console.log("提交参数", this.editForm);

        const { data: res } = await this.$http.put(
          "platform-auth/user",
          this.editForm
        );
        if (res.code == -1) return this.$message.error(res.message);
        // 隐藏编辑对话框
        this.editDialogVisible = false;
        this.$message.success("修改成功！");
        this.getData();
      });
    },
    //删除
    async deleteData(row) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "继续",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult == "confirm") {
        const ids = [];
        ids.push(row.user_id);

        console.log("删除项", ids);
        const { data: res } = await this.$http.delete("platform-auth/user", {
          data: ids,
        });
        if (res.code == -1) return this.$message.error(res.message);
        this.$message.success("删除成功！");
        this.getData();
      }
    },
    // 批量删除
    async removeSelected() {
      if (this.multipleSelection.length == 0) return;

      console.log(this.multipleSelection);
      const ids = [];
      this.multipleSelection.forEach((v) => {
        ids.push(v.user_id);
      });

      const confirmResult = await this.$confirm(
        "此操作将永久删除选定项目, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult == "confirm") {
        console.log("删除项", ids);
        const { data: res } = await this.$http.delete("platform-auth/user", {
          data: ids,
        });
        if (res.code == -1) return this.$message.error(res.message);
        this.$message.success("删除成功！");
        this.getData();
      }
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize;
      this.getData();
    },
    //行样式
    RowClass({ rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "s_s";
      } else {
        return "t_s";
      }
    },
  },
};
</script>

<style lang='less' scoped>
.el-table {
  /deep/ .s_s {
    background: #228b22;
    color: white;
  }

  /deep/ .t_s {
    background: #6b8e23;
    color: white;
  }

  // 鼠标悬浮时样式
  /deep/ .el-table__body tr:hover > td {
    background-color: #3c3c3c !important;
    color: goldenrod !important;
    font-size: 16px;
  }
}
</style>