<template>
  <div>
    <div class="search">
      <div>
        <div>
          店铺：
          <el-select
            v-model="queryInfo.storeId"
            filterable
            clearable
            remote
            placeholder="选择或输入店名查询"
            :remote-method="searchStore"
            @clear="loadStore"
            @change="conditionChange"
            :loading="loadingStore"
          >
            <el-option
              v-for="item in storeOptions"
              :key="item.store_id"
              :label="item.store_name"
              :value="item.store_id"
            >
            </el-option>
          </el-select>
        </div>

        <div>
          账户类型：
          <el-select
            v-model="queryInfo.accountKindId"
            clearable
            placeholder="选择账户类型"
            @change="conditionChange"
          >
            <el-option
              v-for="item in kindOptions"
              :key="item.account_kind_id"
              :label="item.account_kind_name"
              :value="item.account_kind_id"
            >
            </el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="getData">查询</el-button>
      </div>
      <div>
        合计：<span
          style="font-weight: bold"
          v-for="item in amountSum"
          :key="item.id"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
            formatAmount(item.current_balance_sum)
          }}&nbsp;{{ item.currency_name }}</span
        >
      </div>
    </div>

    <div class="accountBox">
      <div v-for="item in ClassData" :key="item.id">
        <div class="box">
          <div style="font-size: 20px">
            {{ item.account_name }}（{{ item.store_name }}）
          </div>
          <div style="color: #4b4b4b">账户类型：{{ item.account_kind_name }}</div>
          <div v-for="subItem in item.balances" :key="subItem.id">
            {{ formatAmount(subItem.current_balance) }}&nbsp;&nbsp;{{
              subItem.currency_name
            }}
          </div>
          <div>
            <el-button
              type="text"
              @click="
                $router.push({
                  path: '/accountFlow',
                  query: {
                    storeName: item.store_name,
                    accountId: item.account_id,
                    accountName: item.account_name,
                    platform:$route.query.platform
                  },
                })
              "
              >查看流水</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 分页区域 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageIndex"
      :page-sizes="[42, 100, 200]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total"
    ></el-pagination>
  </div>
</template>
  
  <script>
import AmountTool from "../../util/AmountTool";
export default {
name: "Cash940AccountTotal",

  data() {
    return {
      storeOptions: [],
      loadingStore: false,

      kindOptions: [],

      //查询条件
      queryInfo: {
        storeId:"",
        accountKindId:"",
        pageIndex: 1,
        pageSize: 36,
      },

      pageInfo: {
        total: 0,
      },
      ClassData: [],
      amountSum: [],
    };
  },
  created() {
    this.loadStore();
    this.loadKind();
    this.getData();
  },
  methods: {
    async getData() {
      console.log("查询参数",this.queryInfo)
      const { data: res } = await this.$http.get(
        "platform-api/cash940/Total/GetAccountBalancePage",
        {
          params: this.queryInfo,
        }
      );
      if (res.code == -1) return this.$message.error(res.message);

      console.log("账户余额=====>", res.data);
      this.ClassData = res.data.accountList;
      this.amountSum = res.data.amountSum;
      this.pageInfo = res.data.pageInfo;
      // this.ClassData = JSON.parse(JSON.stringify(res.data));
    },
    async loadStore() {
      const { data: res } = await this.$http.get(
        "platform-api/cash940/Total/SearchStore?storeName=%"
      );
      if (res.code == -1) return this.$message.error(res.message);

      console.log("店铺=====>", res.data);
      this.storeOptions = res.data;
    },
    async loadKind() {
      const { data: res } = await this.$http.get(
        "platform-api/cash940/Total/SearchAccountKind"
      );
      if (res.code == -1) return this.$message.error(res.message);

      console.log("账户类型=====>", res.data);
      this.kindOptions = res.data;
    },
    async searchStore(query) {
      this.loadingStore = true;
      const { data: res } = await this.$http.get(
        "platform-api/cash940/Total/SearchStore?storeName=" + query
      );
      this.loadingStore = false;
      if (res.code == -1) return this.$message.error(res.message);

      console.log("店铺=====>", res.data);
      this.storeOptions = res.data;
    },
    //查询条件变化
    conditionChange() {
      this.queryInfo.pageIndex = 1;
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize;
      this.getData();
    },
    formatAmount(amount) {
      return AmountTool.formatAmount(amount);
    },
  },
};
</script>
  
  <style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 20px;
  > div {
    display: flex;
    align-items: center;
    > div {
      margin-right: 10px;
    }
  }
  :nth-child(2) {
    margin-left: 30px;
  }
}
.accountBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box;
  .box {
    background-color: #228b22;
    border-radius: 3px;
    width: 320px;
    padding: 15px;
    margin: 10px 0;
    color: #fff;
  }
}
</style>
  