<template>
  <div>
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="'210px'">
        <el-menu :default-openeds="openeds" @select="handleNodeClick">
          <!-- 引入组件 -->
          <menu-tree :menuData="menuList"></menu-tree>
        </el-menu>
      </el-aside>
      <!-- 内容主体 -->
      <el-main>
        <!-- 引入组件 -->
        <span v-html="innerHtml"></span>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import MenuTree from '../components/MenuTree'
export default {
  components: {
    MenuTree
  },
  data() {
    return {
      menuList: [],
      openeds:[],
      innerHtml: "",
    };
  },
  created() {
    this.getHelp();
  },
  methods: {
    async getHelp() {
      const { data: res } = await this.$http.get("school/help");
      if (res.code == -1) return this.$message.error(res.msg);
      console.log("help====>", res.data);
      // 重新组织数据
      this.menuList = this.parseTree(res.data, 0, 1);
      console.log("help2222222====>", this.menuList);
    },
    //转换为树形结构
    parseTree(data, p_id, level) {
      var nodes = [];
      data.forEach((item) => {
        if (item.p_id == p_id) {
          item.level = level
          nodes.push(item);
          this.openeds.push(item.id);
          item.children = this.parseTree(data, item.id, level+1);
        }
      });
      return nodes;
    },
    async handleNodeClick(menuId) {
      const { data: res } = await this.$http.get("school/help/getDetail?id="+menuId);
      console.log(res)
      if (res.code == 1) {
        this.innerHtml = res.data.content;
      }      
    },
  },
};
</script>

<style lang='less' scoped>
</style>