<template>
  <div>
    <!-- 卡片视图 -->
    <el-card>
      <div>
        <el-select v-model="queryInfo.app" placeholder="请选择app">
          <el-option v-for="item in app_options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>&nbsp;
        <el-select v-model="queryInfo.os" placeholder="请选择操作系统">
          <el-option v-for="item in os_options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>&nbsp;
        <el-button type="primary" @click="query()">查询</el-button>
        <el-button type="primary" @click="showAddDialog">添加</el-button>
      </div>
      <el-table :data="data" row-key="id" border default-expand-all>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="app" label="APP"></el-table-column>
        <el-table-column prop="os" label="操作系统"></el-table-column>
        <el-table-column prop="version_code" label="版本号"></el-table-column>
        <el-table-column prop="version_name" label="版本名字"></el-table-column>
        <el-table-column prop="file_name" label="文件名"></el-table-column>
        <el-table-column prop="file_path" label="地址"></el-table-column>
        <el-table-column prop="create_time" label="上传时间"></el-table-column>
        <el-table-column prop="create_ip" label="IP"></el-table-column>
        <el-table-column width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pageIndex" :page-sizes="[18, 50, 100]" :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="pageInfo.total"></el-pagination>
    </el-card>
    <el-dialog title="添加" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="120px" size="small">
        <el-form-item label="APP" prop="app">
          <el-select v-model="addForm.app" placeholder="请选择app">
            <el-option v-for="item in app_options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作系统" prop="os">
          <el-select v-model="addForm.os" placeholder="请选择操作系统">
            <el-option v-for="item in os_options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本号" prop="version_code">
          <el-input v-model="addForm.version_code"></el-input>
        </el-form-item>
        <el-form-item label="版本名称" prop="version_name">
          <el-input v-model="addForm.version_name"></el-input>
        </el-form-item>
        <el-form-item label="说明" prop="version_description">
          <el-input v-model="addForm.version_description"></el-input>
        </el-form-item>
        <el-form-item label="文件" prop="FileList">
          <el-upload action="" accept=".apk,.wgt" :limit="1" :auto-upload="false" :on-change="onchangeTpai"
            :on-remove="onremoveTpai" :file-list="addForm.FileList">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">点击或将文件拖动到此区域上传</div>
            <div slot="tip" class="el-upload__tip">
              文件不能不超过50M
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //app
      app_options: [{
        value: '',
        label: '全部'
      }, {
        value: 'fish-tablet',
        label: '飞鱼平板端'
      }, {
        value: 'fish-phone',
        label: '飞鱼手机端'
      }, {
        value: 'fish-tv',
        label: '飞鱼牌价'
      },],
      //操作系统
      os_options: [{
        value: '',
        label: '全部'
      }, {
        value: 'android',
        label: 'Android'
      }, {
        value: 'ios',
        label: 'IOS'
      }, {
        value: 'windows',
        label: 'windows'
      },],
      //查询条件
      queryInfo: {
        app: "",
        os: "",
        pageIndex: 1,
        pageSize: 18,
      },

      pageInfo: {
        total: 0,
      },
      data: [],

      addDialogVisible: false,
      // 上级添加
      addForm: {
        app: '',
        os: '',
        version_code: "",
        version_name: '',
        version_description: '',
        FileList: [],
      },
      addFormRules: {
        app: [
          { required: true, message: '请选择app', trigger: 'blur' },
        ],
        os: [
          { required: true, message: '请选择操作系统', trigger: 'blur' },
        ],
        version_code: [
          { required: true, message: '请输入版本号', trigger: 'blur' },
        ],
      },

    };
  },
  created() {
    this.getData();
  },
  methods: {
    query() {
      this.queryInfo.pageIndex = 1;
      this.pageInfo.total = 0;
      this.getData();
    },
    async getData() {
      const { data: res } = await this.$http.get(
        "platform-file/api/setupfile",
        {
          params: this.queryInfo,
        }
      );
      if (res.code == -1) return this.$message.error(res.message);

      console.log("data=====>", res.data);
      this.data = res.data.list;
      this.pageInfo = res.data.pageInfo;
    },

    showAddDialog() {
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },
    //添加
    async add() {
      // 提交请求前，表单预验证
      this.$refs.addFormRef.validate(async valid => {
        // 表单预校验失败
        if (!valid) return

        // const { data: res } = await this.$http.post('platform-foundation/api/internationalnumber', this.addForm)

        var formData = new FormData();
        formData.append("files", this.addForm.FileList[0].raw);
        formData.append("app", this.addForm.app);
        formData.append("os", this.addForm.os);
        formData.append("version_code", this.addForm.version_code);
        formData.append("version_name", this.addForm.version_name);
        formData.append("version_description", this.addForm.version_description);

        const { data: res } = await this.$http.post("platform-file/api/SetUpFile", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          maxContentLength: Infinity, // 设置为无限制
          timeout: 60 * 1000 // 设置超时时间（例如60秒）
        });
        console.log("上传", res)

        if (res.code == -1) return this.$message.error(res.message);
        this.$message.success('添加成功！')
        // 隐藏添加对话框
        this.addDialogVisible = false
        this.getData()
      })
    },

    async onchangeTpai(file, fileList) {
      if (file.size > 50 * 1024 * 1024) {
        this.$message.error("文件超过50M了");
      }
      else {
        this.addForm.FileList = fileList;
      }
    },
    onremoveTpai(file, fileList) {
      this.addForm.FileList = fileList;
      console.log("onremoveTpai");
    },
    /** 删除 */
    async handleDelete(index, row) {
      const confirmResult = await this.$confirm(
        '此操作将永久删除, 是否继续?',
        '提示',
        {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult == 'confirm') {
        const { data: res } = await this.$http.delete("platform-file/api/SetUpFile", {
          data: {
            app: row.app,
            os: row.os,
            version_code: row.version_code
          },
        });
        if (res.code == -1) return this.$message.error(res.message);
        this.getData();
      }
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize;
      this.getData();
    },
  },
};
</script>

<style lang='less' scoped></style>