<template>
    <div>
      <!-- 面包屑导航区 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资产统计</el-breadcrumb-item>
        <el-breadcrumb-item>资产</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 卡片视图 -->
      <!-- <component :is="Cash940AccountTotal" v-if="currentPlatformCode === 'cash940'"></component>
      <component :is="Cash940AccountTotal" v-if="currentPlatformCode === 'cash940'"></component> -->
      <div v-if="currentPlatformCode === 'cash940'">     
        <Cash940AccountTotal></Cash940AccountTotal>   
      </div>
      <div v-if="currentPlatformCode === 'trade-center'">
        <TradeCenterAccountTotal></TradeCenterAccountTotal>
      </div>
    </div>
  </template>
  
  <script>
  import Cash940AccountTotal from "./total/Cash940-Account-Total.vue";
  import TradeCenterAccountTotal from "./total/Trade-Center-Account-Total.vue";
  export default {
    components: { Cash940AccountTotal,TradeCenterAccountTotal },
    data() {
      return {
        currentPlatformCode: "",    
        // currentComponent:"Cash940AccountTotal"
      };
    },
    created() {
      this.currentPlatformCode = this.$route.query.platform;
    },
    beforeRouteUpdate(to, from, next) {
      console.log("老参数", from.query.platform);
      console.log("新参数", to.query.platform);
  
      this.currentPlatformCode = to.query.platform;
      next(); //必须调用next()来解析路由
    },
    methods: {
    },
  };
  </script>
  
  <style lang="less" scoped>
  </style>
  