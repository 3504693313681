<!--
作用：主界面
auth：xzl 2022-7-22 -->
<template>
  <div>
    <!-- 头部 -->
    <el-header>
      <div class="toggle-button">
        <i style="cursor: pointer" :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
          @click="togleCollapse"></i>
        <img src="img/logo.png" /><span
         :class="item.platform_code === currentPlatformCode ? 'select-platform' : 'unselect-platform'"
         @click="switchPlatform(item)"
          v-for="item in platFormList" :key="item.platform_code">&nbsp;&nbsp;&nbsp;{{ item.platform_name }}</span>
      </div>
      <div>
        <!-- <el-dropdown @command="handleCommand">
          <span style="cursor: pointer; color:#fff">
            {{loginUserInfo.nick_name}}<i style="margin-left: 5px" class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-lock" command="pwd">修改密码</el-dropdown-item>
            <el-dropdown-item divided icon="el-icon-switch-button" command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <i class="iconfont icon-touxiang"></i>
        <span style="font-size: 14px; margin-right: 20px">
          {{ loginUserInfo.nick }}
        </span>
        <el-button icon="el-icon-lock" type="text" @click="setpwd">修改密码</el-button>
        <el-button icon="el-icon-help" type="text" @click="help">帮助</el-button>
        <el-button icon="el-icon-switch-button" type="text" @click="logout">退出登录</el-button>
      </div>
    </el-header>
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '210px'">
        <el-menu :collapse="isCollapse" :collapse-transition="false" :router="true" :default-active="activePath"
          background-color="#333744" text-color="#fff" active-text-color="#aeae13" :unique-opened="true">
          <!-- :unique-opened="true"->只允许展开一个菜单 -->
          <!-- :collapse-transition="false" -> 关闭动画 -->
          <!-- router -> 导航开启路由模式 -->
          <!-- 一级菜单  -->
          <el-submenu :index="item.menu_code" v-for="item in menuList" :key="item.menu_code">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="item.icon"></i>
              <!-- 文本 -->
              <span>{{ item.menu_name }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item :index="'/' + subItem.menu_code+'?platform='+currentPlatformCode" v-for="subItem in item.children" :key="subItem.menu_code"
              @click="saveNavState(subItem)">
              <!-- 导航开启路由模式：
                将index值作为导航路由 -->
              <!-- 二级菜单的模板区域 -->
              <template slot="title">
                <i :class="subItem.icon"></i>
                <span>{{ subItem.menu_name }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 内容主体 -->
      <el-main>
        <!-- <div>
          <span v-if="tags.length > 0">快捷菜单</span>
          <el-tag
            v-for="item in tags"
            :key="item.menu_code"
            closable
            style="margin-right: 5px; color: #000; margin-bottom: 5px"
            @close="closeTag(item)"
            @click="tagClick(item)"
            >{{ item.menu_name }}</el-tag
          >
        </div> -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //平台集合
      platFormList: [],
      //当前选择的平台代码
      currentPlatformCode: "",
      // 左侧菜单数据
      menuList: [],
      // 默认不折叠
      isCollapse: false,
      // 被激活导航地址
      activePath: "",
      loginUserInfo: { nick_name: "" },
      tags: [],
    };
  },
  created() {
    // 获取菜单
    this.getMenuList();
    this.activePath = window.sessionStorage.getItem("activePath");

    // 获取登录用户信息
    this.getLoginUserInfo();
  },
  methods: {
    closeTag(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
    },
    tagClick(tag) {
      // window.sessionStorage.setItem("activePath", "/" + tag.path);
      this.$router.push({ path: "/" + tag.menu_code });
    },
    // 获取请求菜单
    async getMenuList() {
      const { data: res } = await this.$http.get("platform-auth/user/getLoginUserPlatformMenus");
      if (res.code == -1) return this.$message.error(res.message);
      console.log("菜单====>", res.data);
      this.platFormList = res.data;
      //当前选择的平台代码
      this.currentPlatformCode = res.data[0].platform_code;

      // 重新组织菜单
      const menusLevel1 = [];
      res.data[0].menus.forEach((element) => {
        if (
          element.parent_code === null ||
          element.parent_code === "0" ||
          element.parent_code === undefined ||
          element.parent_code === ""
        ) {
          menusLevel1.push(element);

          const menusLevel2 = [];
          res.data[0].menus.forEach((v) => {
            if (v.parent_code === element.menu_code) {
              menusLevel2.push(v);
            }
          });
          element.children = menusLevel2;
        }
      });

      this.menuList = menusLevel1; // res.list
    },
    // 菜单的水平折叠与展开
    togleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    // 保存连接的激活地址
    saveNavState(menuitem) {
      // window.sessionStorage.setItem("activePath", activePath);

      if (this.tags.indexOf(menuitem) >= 0)
        this.tags.splice(this.tags.indexOf(menuitem), 1);
      this.tags.push(menuitem);
    },
    setpwd() {
      this.$router.push("/setpwd");
    },
    async logout() {
      const confirmResult = await this.$confirm("确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel

      if (confirmResult == "confirm") {
        // 清空token、school_id、school_name
        window.sessionStorage.clear();
        this.$router.push("/login");

        //调试注销接口
        // this.$http.get('auth/api/Account')
      }
    },
    //登录用户信息
    async getLoginUserInfo() {
      const { data: res } = await this.$http.get(
        "platform-auth/user/getloginuserinfo"
      );
      if (res.code == -1) return this.$message.error(res.message);
      console.log("登录用户=====>", res.data);
      this.loginUserInfo = res.data;
    },
    help() {
      // window.open("https://lonet.vip/school/#/help",'_blank');
    },
    // 切换平台
    switchPlatform(selectedPlatform){
      // 选择的平台代码
      this.currentPlatformCode = selectedPlatform.platform_code;
      // 重新组织菜单
      const menusLevel1 = [];
      selectedPlatform.menus.forEach((element) => {
        if (
          element.parent_code === null ||
          element.parent_code === "0" ||
          element.parent_code === undefined ||
          element.parent_code === ""
        ) {
          menusLevel1.push(element);

          const menusLevel2 = [];
          selectedPlatform.menus.forEach((v) => {
            if (v.parent_code === element.menu_code) {
              menusLevel2.push(v);
            }
          });
          element.children = menusLevel2;
        }
      });

      this.menuList = menusLevel1; // res.list

      console.log("路由",this.activePath)
      console.log("路由2",this.$router.currentRoute)
      console.log("路由3",selectedPlatform)
      this.$router.push({
        path:this.$router.currentRoute.path,
        query:{          
          platform:selectedPlatform.platform_code,
          t:new Date().getTime(),
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.el-container {
  height: 100%;
}

.el-aside {
  height: calc(100vh - 60px); //减去header的高度60
  background-color: #333744;

  .el-menu {
    border: none;
  }
}

.el-header {
  //底部边框2px的半透明
  // border-bottom: 2px solid rgba(234, 237, 241, 0.5f);
  height: 60px; //设置为固定高度，方便计算菜单和工作区的高度，从而实现只有工作区滚动效果
  background-color: #3c3c3c;
  color: #bbbbbb;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  font-size: 20px;

  //logo
  img {
    height: 30px;
    margin-left: 18px;
  }

  .toggle-button {
    margin-left: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2em;
    // span {
    //   font-size: 12px;
    // }
  }

  div {
    display: flex;
    align-items: center;

    button {
      color: #bbbbbb;
    }
  }
}

.el-main {
  background-color: #eaedf1;
  height: calc(100vh - 60px); //减去header的高度60
}

.iconfont {
  margin-right: 10px;
}

// 选择的平台显示样式
.select-platform {
  color: #aeae13;
  cursor: pointer;
}

// 未选择的平台显示样式
.unselect-platform {
  color: #bbbbbb;
  cursor: pointer;
}
</style>
