<!--
作用：设置密码
auth：xzl 2022-7-22 -->
<template>
  <div>    
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>管理设置</el-breadcrumb-item>
      <el-breadcrumb-item>修改密码</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>      
      <el-form
        :model="addUserForm"
        ref="addUserFormRef"
        :rules="addUserFormRules"
        label-width="100px"
      >
        <el-form-item label="原密码" prop="oldPwd">
          <el-input v-model="addUserForm.oldPwd" show-password placeholder="输入原密码" style="width: 50%;"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input v-model="addUserForm.newPwd" show-password placeholder="输入新密码" style="width: 50%;"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="newPwd2">
          <el-input v-model="addUserForm.newPwd2" show-password placeholder="再次输入确认" style="width: 50%;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm">重填</el-button>
          <el-button type="primary" @click="save">保存修改</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    var checkNewPwd2 = (rule, value, callback) => {
      if (value.length < 2 || value.length > 16) {
        callback(new Error('长度在2～16个字'))
      }
      if (value !== this.addUserForm.newPwd) {
        callback(new Error('密码不一致'))
      }
      return callback()
    }
    return {
      // 表单
      addUserForm: {
        oldPwd: '',
        newPwd: '',
        newPwd2: ''
      },
      // 表单验证规则
      addUserFormRules: {
        oldPwd: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          {
            min: 2,
            max: 16,
            message: '原密码的长度在2～16个字',
            trigger: 'blur'
          }
        ],
        newPwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            min: 2,
            max: 16,
            message: '新密码的长度在2～16个字',
            trigger: 'blur'
          }
        ],
        newPwd2: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { validator: checkNewPwd2, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 添加
    save () {
      // 提交请求前，表单预验证
      this.$refs.addUserFormRef.validate(async valid => {

        // 表单预校验失败时终止
        if (!valid) return

        const { data: res } = await this.$http.post('platform-auth/user/ModifyLoginPwd', this.addUserForm)
        if (res.code === 1) {
          this.$message.success('提交成功！')
          this.$refs.addUserFormRef.resetFields()
        } else {
          this.$message.error(res.message)
        }
      })
    },

    //重置
    resetForm () {
      this.$refs.addUserFormRef.resetFields()
    }
  }
}
</script>

// <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='less' scoped>
  /* el-input样式穿透 */
  // .el-input /deep/ .el-input__inner {
  //   font-family: aspy;
  // }
</style>
