<template>
    <div>
        <!-- 面包屑导航区 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>管理设置</el-breadcrumb-item>
            <el-breadcrumb-item>角色管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 卡片视图 -->
        <el-card>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-button type="primary" @click="showAddDialog">添加</el-button>
                    <el-button type="primary" @click="removeSelected">删除选择</el-button>
                </el-col>
            </el-row>
            <el-table :data="data" border @selection-change="handleSelectionChange" :row-class-name="RowClass">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="role_id" label="角色代码"> </el-table-column>
                <el-table-column prop="role_name" label="角色名称"></el-table-column>
                <el-table-column prop="des" label="角色说明"></el-table-column>

                <el-table-column width="180">
                    <template slot-scope="scope">
                        <el-button type="warning" size="mini" @click="showEditDialog(scope.row)" icon="el-icon-edit">编辑
                        </el-button>
                        <el-button type="danger" size="mini" @click="deleteData(scope.row)" icon="el-icon-delete">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog title="添加" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed" center>
            <el-tabs type="border-card">
                <el-tab-pane label="基本信息">
                    <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="120px" size="small">
                        <el-form-item label="角色代码" prop="role_id">
                            <el-input v-model="addForm.role_id"></el-input>
                        </el-form-item>
                        <el-form-item label="角色名称" prop="role_name">
                            <el-input v-model="addForm.role_name"></el-input>
                        </el-form-item>
                        <el-form-item label="角色说明" prop="des">
                            <el-input v-model="addForm.des"></el-input>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="菜单项">
                    <el-tabs v-model="activeplatform" tabPosition="left">
                        <el-tab-pane v-for="item in platformOptions" :label="item.platform_name" :key="item.platform_code"
                            :name="item.platform_code">
                            <div style="height: 50vh;overflow-y: scroll;"><el-tree :data="item.menuData" :props="props"
                                    :ref="'tree-' + item.platform_code" default-expand-all show-checkbox accordion>
                                </el-tree>
                            </div>

                        </el-tab-pane>
                    </el-tabs>
                </el-tab-pane>
            </el-tabs>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="add">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="修改" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed" center>
            <el-tabs type="border-card">
                <el-tab-pane label="基本信息">
                    <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" label-width="120px"
                        size="small">
                        <el-form-item label="角色代码" prop="role_id">
                            <el-input v-model="editForm.role_id"></el-input>
                        </el-form-item>
                        <el-form-item label="角色名称" prop="role_name">
                            <el-input v-model="editForm.role_name"></el-input>
                        </el-form-item>
                        <el-form-item label="角色说明" prop="des">
                            <el-input v-model="editForm.des"></el-input>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="菜单项">
                    <el-tabs v-model="activeplatform" tabPosition="left">
                        <el-tab-pane v-for="item in editForm.platformOptions" :label="item.platform_name"
                            :key="item.platform_code" :name="item.platform_code">
                            <div style="height: 50vh;overflow-y: scroll;">
                                <el-tree :data="item.menuData" :props="props" node-key="menu_code"
                                    :default-checked-keys="item.defaultCheckedKeys" :ref="'tree-' + item.platform_code"
                                    default-expand-all show-checkbox accordion check-strictly>
                                </el-tree>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </el-tab-pane>
            </el-tabs>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="edit">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            //平台选项
            platformOptions: [],
            //多选数据
            multipleSelection: [],
            // 获取的列表数据
            data: [],
            props: {
                label: "menu_name",
                children: "children",
            },
            activeplatform: "",

            addDialogVisible: false,
            // 上级添加
            addForm: {
                role_id: '',
                role_name: '',
                des: "",
                roleMenus: [],
            },
            addFormRules: {
                role_id: [
                    { required: true, message: '请输入代码', trigger: 'blur' },
                    {
                        min: 2,
                        max: 20,
                        message: '长度在2～10个字',
                        trigger: 'blur'
                    }
                ],
                role_name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在2～50个字',
                        trigger: 'blur'
                    }
                ],
            },

            editDialogVisible: false,
            editForm: {
            },
            editFormRules: {
                role_id: [
                    { required: true, message: '请输入代码', trigger: 'blur' },
                    {
                        min: 2,
                        max: 20,
                        message: '长度在2～10个字',
                        trigger: 'blur'
                    }
                ],
                role_name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                    {
                        min: 2,
                        max: 50,
                        message: '长度在2～50个字',
                        trigger: 'blur'
                    }
                ],
            },
        };
    },
    created() {
        this.getData();
        this.getPlatform();
    },
    methods: {
        async getData() {
            const { data: res } = await this.$http.get("platform-auth/api/role");
            if (res.code == -1) return this.$message.error(res.message);
            console.log(res.data)

            this.data = res.data;
        },
        async getPlatform() {
            const { data: res } = await this.$http.get("platform-auth/api/Platform");
            if (res.code == -1) return this.$message.error(res.message);
            const { data: cRes } = await this.$http.get("platform-auth/Menu");
            if (cRes.code == -1) return this.$message.error(cRes.message);
            // 重新组织菜单
            const menusLevel1 = [];
            cRes.data.forEach((element) => {
                if (
                    element.parent_code === null ||
                    element.parent_code === "0" ||
                    element.parent_code === undefined ||
                    element.parent_code === ""
                ) {
                    menusLevel1.push(element);
                    const menusLevel2 = [];
                    cRes.data.forEach((v) => {
                        if (v.parent_code === element.menu_code) {
                            menusLevel2.push(v);
                        }
                    });
                    element.children = menusLevel2;
                }
            });
            //把菜单加入平台
            res.data.forEach((item) => {
                item.menuData = JSON.parse(JSON.stringify(menusLevel1));
            });

            this.platformOptions = res.data;
            this.activeplatform = res.data[0].platform_code;
            console.log(this.platformList);
        },
        query() {
            this.queryInfo.pageIndex = 1
            this.getData()
        },
        //多选
        handleSelectionChange(val) {
            this.multipleSelection = val
        },

        showAddDialog() {
            this.addDialogVisible = true;
        },
        addDialogClosed() {
            this.$refs.addFormRef.resetFields();
        },
        //添加
        async add() {
            // 提交请求前，表单预验证
            this.$refs.addFormRef.validate(async valid => {
                // 表单预校验失败
                if (!valid) return

                this.platformOptions.forEach((v) => {
                    let menus = [];
                    this.$refs[`tree-${v.platform_code}`][0]
                        .getCheckedNodes(false, true)
                        .forEach((m) => {
                            menus.push({
                                menu_code: m.menu_code,
                                menu_name: m.menu_name,
                            });
                        });
                    // v.checkedList = this.$refs[`tree-${v.platform_code}`][0];
                    this.addForm.roleMenus.push({
                        platform_code: v.platform_code,
                        menus: menus,
                    });
                });

                console.log("提交参数", this.addForm)

                const { data: res } = await this.$http.post('platform-auth/api/role', this.addForm)
                if (res.code == -1) return this.$message.error(res.message);
                this.$message.success('添加成功！')
                // 隐藏添加对话框
                this.addDialogVisible = false
                this.getData()
            })
        },
        async showEditDialog(row) {
            //获取角色对应的平台和菜单
            const { data: res } = await this.$http.get("platform-auth/api/role/getRolePlatformMenus", {
                params: { roleCode: row.role_id }
            });
            if (res.code == -1) return this.$message.error(res.message);
            console.log("当前角色的平台和菜单", res);

            let editPlatformOptions = JSON.parse(JSON.stringify(this.platformOptions));
            editPlatformOptions.forEach(v => {
                v.defaultCheckedKeys = [];
                res.data.forEach(p => {
                    if (v.platform_code === p.platform_code) {
                        p.menus.forEach(m => {
                            v.defaultCheckedKeys.push(m.menu_code);
                        });
                    }
                })
            })
            console.log("编辑时的平台树", editPlatformOptions);


            let temp = JSON.parse(JSON.stringify(row));
            temp.platformOptions = editPlatformOptions;
            this.editForm = temp;

            this.editDialogVisible = true;
            console.log("编辑数据", this.editForm);
        },
        editDialogClosed() {
            this.$refs.editFormRef.resetFields()
        },
        //修改
        async edit() {
            // 提交请求前，表单预验证
            this.$refs.editFormRef.validate(async valid => {
                // 表单预校验失败
                if (!valid) return

                this.editForm.roleMenus = [];
                this.editForm.platformOptions.forEach((v) => {
                    let menus = [];
                    this.$refs[`tree-${v.platform_code}`][0]
                        .getCheckedNodes(false, true)
                        .forEach((m) => {
                            menus.push({
                                menu_code: m.menu_code,
                                menu_name: m.menu_name,
                            });
                        });
                    // v.checkedList = this.$refs[`tree-${v.platform_code}`][0];
                    this.editForm.roleMenus.push({
                        platform_code: v.platform_code,
                        menus: menus,
                    });
                });

                console.log("提交参数", this.editForm)

                const { data: res } = await this.$http.put('platform-auth/api/role', this.editForm)
                if (res.code == -1) return this.$message.error(res.message);
                // 隐藏编辑对话框
                this.editDialogVisible = false
                this.$message.success('修改成功！')
                this.getData()
            })
        },
        //删除
        async deleteData(row) {
            const confirmResult = await this.$confirm(
                '此操作将永久删除, 是否继续?',
                '提示',
                {
                    confirmButtonText: '继续',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).catch(err => err)
            // 点击确定 返回值为：confirm
            // 点击取消 返回值为： cancel
            if (confirmResult == 'confirm') {
                const ids = []
                ids.push(row.role_id)

                console.log("删除项", ids)
                const { data: res } = await this.$http.delete('platform-auth/api/role', {
                    data: ids
                })
                if (res.code == -1) return this.$message.error(res.message);
                this.$message.success('删除成功！')
                this.getData()
            }
        },
        // 批量删除
        async removeSelected() {
            if (this.multipleSelection.length == 0) return;

            console.log(this.multipleSelection)
            const ids = []
            this.multipleSelection.forEach(v => {
                ids.push(v.role_id)
            })

            const confirmResult = await this.$confirm(
                '此操作将永久删除选定项目, 是否继续?',
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).catch(err => err)
            // 点击确定 返回值为：confirm
            // 点击取消 返回值为： cancel
            if (confirmResult == 'confirm') {
                console.log("删除项", ids)
                const { data: res } = await this.$http.delete('platform-auth/api/role',
                    {
                        data: ids
                    })
                if (res.code == -1) return this.$message.error(res.message)
                this.$message.success('删除成功！')
                this.getData()
            }
        },
        // 监听 pageSize改变的事件
        handleSizeChange(newSize) {
            this.queryInfo.pageSize = newSize
            this.getData()
        },
        // 监听 页码值 改变事件
        handleCurrentChange(newSize) {
            this.queryInfo.pageIndex = newSize
            this.getData()
        },
        //行样式
        RowClass({ rowIndex }) {
            if (rowIndex % 2 == 1) {
                return "s_s";
            }
            else {
                return "t_s";
            }
        },
    },
};
</script>

<style lang='less' scoped>
.el-table {
    /deep/ .s_s {
        background: #228b22;
        color: white;
    }

    /deep/ .t_s {
        background: #6b8e23;
        color: white;
    }

    // 鼠标悬浮时样式
    /deep/ .el-table__body tr:hover>td {
        background-color: #3c3c3c !important;
        color: goldenrod !important;
        font-size: 16px;
    }
}
</style>