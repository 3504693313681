<!--
作用：登录（仅仅密码）
auth：xzl 2022-7-22 -->
<template>
  <div
    style="
      background-color: #050608;
      width: 100%;
      height: 100%;
      overflow: hidden;
    "
  >
    <star-background />

    <div class="login_box">
      <!-- 头像区 -->
      <div class="title" style=" margin-top:5px;">
        <img src="img/logo.png"><span>CASH940管理平台</span>
      </div>
      <el-form
        class="login_form"
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginFormRules"
      >
        <el-form-item label-width="0" prop="username">
          <el-input
            v-model="loginForm.username"
            prefix-icon="iconfont icon-zhanghaodenglu"
            placeholder="填写用户名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label-width="0" prop="password">
          <el-input
            v-model="loginForm.password"
            prefix-icon="iconfont icon-mima1"
            type="password"
            placeholder="填写密码"
            clearable
          ></el-input>
        </el-form-item>               
        <el-form-item>
          <div class="buttonrow"><el-button type="primary" @click="login" style="width: 48%"
            >进入</el-button
          >
          <el-button type="info" @click="resetLoginForm" style="width: 48%"
            >重填</el-button
          ></div>          
        </el-form-item>        
        <span style="display: block; margin-top: -5px;margin-bottom: 15px;margin-left: 3px; color:white;font-size: 12px;">登录或完成注册即代表你同意<a target="_blank" href="https://sohu.com" style="color: #aeae13;">《服务协议》和《隐私政策》</a></span>
      </el-form>
      
    </div>

    <div
      style="
        position: absolute;
        left: 50%;
        bottom: 1%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: #bbbbbb;
      "
    >
      Copyright © 2023 香港飞鱼科技有限公司
    </div>
  </div>
</template>

<script>
import StarBackground from "../components/StarBackground.vue";
export default {
  beforeCreate: function () {
    document.getElementsByTagName("body")[0].className = "body-bg";
  },

  components: { StarBackground },

  data() {
    return {
      // 账号登录
      loginForm: {
        grant_type: "password",
        username: "",
        password: "",
        client_id: "platform",
        client_secret: "12345678",
      },
      // 表单验证
      loginFormRules: {
        username: [
          { required: true, message: "请填写用户名", trigger: "blur" },
          {
            min: 3,
            max: 11,
            message: "长度在 3 到 11 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请填写密码", trigger: "blur" },
          {
            min: 2,
            max: 18,
            message: "长度在 2 到 18 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 表单重置按钮
    resetLoginForm() {
      // resetFields：element-ui提供的表单方法
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      // 表单预验证
      // valid：bool类型
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return false;
        // this.$http.post('platform-auth/connect/token', this.loginForm): 返回值为promise
        // 返回值为promise，可加await简化操作 相应的也要加async

        const { data: res } = await this.$http.post("platform-auth/connect/token", {
          grant_type: this.loginForm.grant_type,
          username: this.loginForm.username,
          password: this.loginForm.password,
          client_id: this.loginForm.client_id,
          client_secret: this.loginForm.client_secret,
        });
        if (res.code == -1) return this.$message.error(res.message);
        this.$message.success("登录成功");
        // 1、将登陆成功之后的token, 保存到客户端的sessionStorage中; localStorage中是持久化的保存
        //   1.1 项目中出现了登录之外的其他API接口，必须在登陆之后才能访问
        //   1.2 token 只应在当前网站打开期间生效，所以将token保存在sessionStorage中
        window.sessionStorage.setItem("token", "Bearer " + res.access_token);
        window.sessionStorage.setItem("refresh_token", res.refresh_token);

        // 2、通过编程式导航跳转到后台主页, 路由地址为：/home
        this.$router.push("/home");
      });
    },
  },
};
</script>

<style lang='less'>
.body-bg {
  background-attachment: fixed;
  overflow: hidden;
}
</style>

// <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='less' scoped>
// .body-bg {
//   background-attachment: fixed;
//   overflow: hidden;
// }

.login_box {
  width: 450px;
  height: 270px;
  /* background-color: #fff; */
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 45%;
  -webkit-transform: translate(-50%, -45%);
  transform: translate(-50%, -45%);
  box-shadow: 0 0 10px 3px #ddd;

  .title{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aeae13;
    font-size: 28px;
    img{
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
}
.login_form {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;  
  .el-form-item{
    // 默认是22，控制formitem的间距
    margin-bottom: 10px;
  }
  // 校验提示的样式
  .el-form-item /deep/ .el-form-item__error {
    color: yellowgreen;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 25%;
    left: 200px;
  }
  /* el-input样式穿透 */
  .el-input /deep/ .el-input__inner {
    background-color: transparent;
    // font-family: aspy;
  }
}
.buttonrow{
  display: inline-flex;
  justify-content: space-between;
  width:100%;
}
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>