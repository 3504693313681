
function formatAmount(amount) {
    // 去除小数点前后多余的0
    amount = parseFloat(amount);
    
    if (isNaN(amount)) return '';

    amount = amount / 100;
  
    var parts = String(amount).split(".");
    var integerPart = parts[0];
    var decimalPart = parts.length > 1 ? "." + parts[1] : "";
  
    // 每三位添加一个逗号
    integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  
    return integerPart + decimalPart;
}
function formatRate(rate) {
    // 去除小数点前后多余的0
    rate = parseFloat(rate);
    
    if (isNaN(rate)) return '';
  
    var parts = String(rate).split(".");
    var integerPart = parts[0];
    var decimalPart = parts.length > 1 ? "." + parts[1] : "";
  
    // 每三位添加一个逗号
    integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  
    return integerPart + decimalPart;
}
export default {
    formatAmount,
    formatRate
}  