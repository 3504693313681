<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商户管理</el-breadcrumb-item>
      <el-breadcrumb-item>交易单</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <div class="search-box">
        <div>
          <el-date-picker
            v-model="value2"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
            @change="handleDateChange"
          >
          </el-date-picker>
        </div>
        <div>
          选择商家:
          <el-popover
            placement="bottom"
            width="200"
            v-model="tenantPopover"
            trigger="click"
          >
            <div class="popover-content">
              <div
                v-for="(item, index) in tenantList"
                :key="index"
                @click="handleTenantChange(item)"
              >
                {{ item.tenant_name }}
              </div>
            </div>
            <el-button slot="reference">{{ tenantInfo.tenant_name }}</el-button>
          </el-popover>
          <!-- <button @click="sendMessage">发送消息</button> -->
        </div>
        <div>
          选择店铺:
          <el-popover
            placement="bottom"
            width="200"
            v-model="storePopover"
            trigger="click"
          >
            <div class="popover-content">
              <div
                v-for="(item, index) in storeList"
                :key="index"
                @click="handleStoreChange(item)"
              >
                {{ item.store_name }}
              </div>
            </div>
            <el-button slot="reference">{{ storeInfo.store_name }}</el-button>
          </el-popover>
          <!-- <button @click="sendMessage">发送消息</button> -->
        </div>
        <div class="exportExcelBtn">
          <el-button slot="reference" class="btn" @click="exportExcel">
            导出
          </el-button>
        </div>
      </div>
      <el-table :data="data" border>
        <el-table-column prop="store_name" label="店铺"></el-table-column>
        <el-table-column prop="store_exchange_no" label="单号">
        </el-table-column>
        <el-table-column label="客户">
          <template slot-scope="scope">
            <span>
              {{ scope.row.customer_name }}{{ scope.row.customer_no }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="买入">
          <template slot-scope="scope">
            <span
              >{{ formatAmount(scope.row.buy_amount)
              }}{{ scope.row.buy_currency_name }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="卖出">
          <template slot-scope="scope">
            <span
              >{{ formatAmount(scope.row.sale_amount)
              }}{{ scope.row.sale_currency_name }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="汇率">
          <template slot-scope="scope">
            <span>{{ formatAmount(scope.row.rate) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="汇率倒数">
          <template slot-scope="scope">
            <span>{{ formatAmount(scope.row.rate_reciprocal) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="exchange_type_name" label="交易类型">
        </el-table-column>
        <el-table-column prop="create_time" label="时间"> </el-table-column>
        <el-table-column prop="status_name" label="状态"> </el-table-column>
        <!-- <el-table-column prop="des" label="角色说明"></el-table-column> -->
        <el-table-column width="180">
          <template slot-scope="scope">
            <el-button
              type="warning"
              size="mini"
              @click="showEditDialog(scope.row)"
              icon="el-icon-edit"
              >详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageIndex"
        :page-sizes="[60, 80, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
    <el-dialog
      title="详细信息"
      width="950px"
      :visible.sync="detailsWindow"
      center
    >
      <div class="detailsPop">
        <div>
          <div class="detailsList">
            <div class="item-info">
              <div class="item-title">
                <div>{{ detailsInfo.store_exchange_no }}</div>
                <div>
                  客户：{{ detailsInfo.customer_name }}({{
                    detailsInfo.customer_no
                  }})
                </div>
              </div>
              <div class="item-content">
                <div>
                  <div>
                    买入：{{ Number(detailsInfo.buy_amount)
                    }}{{ detailsInfo.buy_currency_name }}
                  </div>
                  <div>
                    卖出：{{ Number(detailsInfo.sale_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                  <div>买汇价：{{ detailsInfo.rate * 1 }}</div>
                </div>
                <div
                  v-if="
                    detailsInfo.exchange_type != 'Cash2Cash' &&
                    detailsInfo.exchange_type != 'Surplus2Cash'
                  "
                >
                  <div>
                    手续费：{{ Number(detailsInfo.fee_amount)
                    }}{{ detailsInfo.fee_currency_name }}
                  </div>
                  <div>
                    自带：{{ Number(detailsInfo.bring_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                  <div>
                    自取：{{ Number(detailsInfo.take_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                </div>
                <div
                  v-if="
                    detailsInfo.exchange_type != 'Cash2Cash' &&
                    detailsInfo.exchange_type != 'Surplus2Cash'
                  "
                >
                  <div>
                    应付：{{
                      Number(detailsInfo.sale_amount) +
                      Number(detailsInfo.bring_amount) -
                      Number(detailsInfo.take_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                  <div>
                    待付：{{ Number(detailsInfo.toBePaidAmount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                </div>
              </div>
              <div
                v-if="
                  detailsInfo.exchange_type == 'Cash2Cash' ||
                  detailsInfo.exchange_type == 'Surplus2Cash'
                "
              >
                <div></div>
                <div>状态：{{ detailsInfo.statusString }}</div>
              </div>
            </div>
            <template>
              <div
                class="header-title"
                v-if="
                  detailsInfo.exchange_type != 'Cash2Cash' &&
                  detailsInfo.exchange_type != 'Surplus2Cash'
                "
              >
                <div
                  v-for="(item, index) in detailsInfo.details"
                  :key="'details' + index"
                  class="item-ul"
                >
                  <div class="line"></div>
                  <div class="item-li">
                    <div
                      v-if="
                        detailsInfo.details && detailsInfo.details.length > 1
                      "
                    >
                      第{{ index + 1 }}笔
                    </div>
                  </div>
                  <div class="item-li">
                    <div class="item-li">
                      <div>
                        收款人：{{ detailsInfo.customer_name }}({{
                          detailsInfo.customer_no
                        }})
                      </div>
                      <div
                        v-for="(cItem, cIndex) in item.cards"
                        :key="'cItem' + cIndex"
                      >
                        <div v-if="cItem.bank_card_type_code == 'BankCard'">
                          {{ cItem.holder_name }}
                          {{ cItem.bank_class_name }} ({{
                            cItem.bank_card_number
                          }})
                        </div>
                        <div
                          v-if="
                            cItem.bank_card_type_code == 'WX' ||
                            cItem.bank_card_type_code == 'Alipay'
                          "
                          class="paymentCode"
                        >
                          <div class="paymentCode-title">
                            {{ cItem.holder_name }}
                          </div>
                          <div class="paymentQrCode-box">
                            <div class="paymentQrCode">好友码：</div>
                          </div>
                          <div class="paymentQrCode-box">
                            <div class="paymentQrCode">付款码：</div>
                          </div>
                        </div>
                        <div
                          v-if="cItem.bank_card_type_code == 'Virtual'"
                          class="paymentCode"
                        >
                          <div class="paymentCode-title">
                            {{ cItem.holder_name }}
                          </div>
                          <div>协议：{{ cItem.network }}</div>
                          &nbsp; &nbsp;
                          <div>地址：{{ cItem.qr_code }}</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      金额：{{ Number(item.amount) }}&nbsp; &nbsp;{{
                        detailsInfo.sale_currency_code
                      }}
                    </div>
                  </div>

                  <div
                    v-if="item.pamentDetails && item.pamentDetails.length > 0"
                  >
                    <div
                      v-for="(detail, dIndex) in item.pamentDetails"
                      :key="'detail' + dIndex"
                      class="itembox"
                    >
                      <div class="item-li">
                        <div v-if="!detail.payment_customer_name">
                          付款人：{{ detail.payment_client_id || "" }}
                          {{ detail.payment_tenant_name }}
                          {{ detail.payment_store_name }}
                          <text v-if="detail.bank_card_type_code == 'BankCard'"
                            >({{ detail.bank_card_number }})</text
                          >
                        </div>
                        <div v-else>
                          付款人：{{ detail.holder_name || "" }}
                          {{ detail.bank_class_name }}
                          <text v-if="detail.bank_card_type_code == 'BankCard'"
                            >({{ detail.bank_card_number }})</text
                          >
                        </div>
                        <div>
                          金额：{{ Number(detail.amount)
                          }}{{ detail.currency_name }}
                        </div>
                      </div>
                      <div class="item-li">
                        <div>时间：{{ detail.payment_time }}</div>
                        <div>状态：{{ detail.statusName }}</div>
                      </div>
                      <div class="item-li attachBtn">
                        <div class="attachment"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value2: "",
      detailsWindow: false,
      detailsInfo: {},
      //查询条件
      queryInfo: {
        // 当前页数
        pageIndex: 1,
        // 每页显示多少数据
        pageSize: 60,
      },
      total: 0,
      setTime: this.getTimestampAtStartOfDay(),
      etTime: this.getEndOfDayTimestamp(),
      // 获取的列表数据
      data: [],
      tenantPopover: false,
      storePopover: false,
      tenantList: [],
      tenantInfo: {},
      storeList: [],
      storeInfo: { store_id: "", store_name: "全部" },
    };
  },
  created() {
    this.getTenantList();
  },
  methods: {
    async exportExcel() {
      const { data: res } = await this.$http.get(
        "platform-api/v1/StoreExchange",
        {
          params: {
            tenant: this.tenantInfo.tenant_id,
            store: this.storeInfo.store_id,
            st: this.setTime,
            et: this.etTime,
            pageIndex: this.queryInfo.pageIndex,
            pageSize: this.total,
          },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      console.log(res);
      let dataList = [];
      dataList = res.data.map((item) => {
        return {
          店铺: item.store_name,
          单号: item.store_exchange_no,
          客户: item.customer_name + "(" + item.customer_no + ")",
          买入: item.buy_amount * 1,
          买入币种: item.buy_currency_name,
          卖出: item.sale_amount * 1,
          卖出币种: item.sale_currency_name,
          汇率: item.rate * 1,
          汇率倒数: item.rate_reciprocal * 1,
          交易类型: item.exchange_type_name,
          时间: item.create_time,
          状态: item.status_name,
        };
      });
      let storeName = this.storeInfo.store_name;
      let time = this.setTime + "至" + this.etTime;
      // 将data数组转换为工作表
      const ws = XLSX.utils.json_to_sheet(dataList);

      // 创建一个新的工作簿
      const wb = XLSX.utils.book_new();

      // 将工作表添加到工作簿中
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // 生成Excel文件
      XLSX.writeFile(wb, storeName + "-交易单-" + time + ".xlsx");
    },

    // sendMessage() {
    //     if (this.connection) {
    //         this.connection.send('SendMessage', 'Hello, Server!');
    //     }
    // },
    getTimestampAtStartOfDay() {
      var now = new Date();
      now.setHours(0, 0, 0, 1); // 设置为当天的00:00:01
      var year = now.getFullYear();
      var month = ("0" + (now.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以需要+1
      var day = ("0" + now.getDate()).slice(-2);
      var hours = ("0" + now.getHours()).slice(-2);
      var minutes = ("0" + now.getMinutes()).slice(-2);
      var seconds = ("0" + (now.getSeconds() + 1)).slice(-2);

      return `${year}-${month}-${day} ${hours}-${minutes}-${seconds}`;
    },
    getEndOfDayTimestamp() {
      const now = new Date();
      now.setHours(23, 59, 59, 999); // 将时、分、秒设置为23、59和59，毫秒设置为999以确保总是最后一秒
      var year = now.getFullYear();
      var month = ("0" + (now.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以需要+1
      var day = ("0" + now.getDate()).slice(-2);
      var hours = ("0" + now.getHours()).slice(-2);
      var minutes = ("0" + now.getMinutes()).slice(-2);
      var seconds = ("0" + now.getSeconds()).slice(-2);

      return `${year}-${month}-${day} ${hours}-${minutes}-${seconds}`;
    },
    async getData() {
      const { data: res } = await this.$http.get(
        "platform-api/v1/StoreExchange",
        {
          params: {
            tenant: this.tenantInfo.tenant_id,
            store: this.storeInfo.store_id,
            st: this.setTime,
            et: this.etTime,
            pageIndex: this.queryInfo.pageIndex,
            pageSize: this.queryInfo.pageSize,
          },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      console.log(res);

      this.data = res.data;
      this.total = res.pageInfo.total;
    },
    // 获取商家列表
    async getTenantList() {
      const { data: res } = await this.$http.get("platform-api/v1/Tenant", {
        params: {},
      });
      if (res.code == -1) return this.$message.error(res.message);
      this.tenantList = res.data;
      this.tenantInfo = res.data[0];
      this.getStoreList();
    },
    // 商家选择
    handleTenantChange(item) {
      this.tenantInfo = item;
      this.getStoreList();
      this.tenantPopover = false;
    },
    // 获取店铺列表
    async getStoreList() {
      const { data: res } = await this.$http.get(
        "platform-api/v1/Tenant/GetStoreInfoByTenant",
        {
          params: { tenant: this.tenantInfo.tenant_id },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      this.storeList = res.data;
      this.storeList.unshift({
        store_id: "",
        store_name: "全部",
      });
      this.storeInfo = {
        store_id: "",
        store_name: "全部",
      };
      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    // 店铺选择
    handleStoreChange(item) {
      this.storeInfo = item;
      this.queryInfo.pageIndex = 1;
      this.getData();
      this.storePopover = false;
    },
    // 日期选择
    handleDateChange(e) {
      if (e) {
        this.setTime = e[0];
        this.etTime = e[1];
      } else {
        this.setTime = this.getTimestampAtStartOfDay();
        this.etTime = this.getEndOfDayTimestamp();
      }
      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    query() {
      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize;
      this.getData();
    },
    async showEditDialog(row) {
      console.log("编辑数据", row);
      const { data: res } = await this.$http.get(
        "platform-api/v1/StoreExchange/GetDetail",
        {
          params: {
            ExchangeID: row.store_exchange_id,
          },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      console.log(res.data);
      res.data.toBePaidAmount = 0;
      res.data.details.forEach((item) => {
        res.data.toBePaidAmount += Number(item.wait_amount);
        if (item.pamentDetails && item.pamentDetails.length > 0) {
          item.pamentDetails.forEach((cItem) => {
            if (cItem.status == "agree") {
              cItem.statusName = "已同意收款";
            } else if (cItem.status == "wait") {
              cItem.statusName = "待确认收款";
            } else {
              cItem.statusName = "已拒绝收款";
            }
            cItem.uploadPath = [];
            if (cItem.attachments && cItem.attachments.length > 0) {
              cItem.attachments.forEach((attach) => {
                cItem.uploadPath.push(attach.file_key);
              });
            }
          });
        }
      });
      this.detailsInfo = res.data;
      this.detailsWindow = true;
    },
    //金额格式化
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);

      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
  },
};
</script>

<style lang="less" scoped>
.search-box {
  display: flex;
  align-items: center;

  > :nth-child(2) {
    margin-left: 50px;
    display: flex;
    align-items: center;

    > span {
      margin-left: 10px;
    }
  }
  > :nth-child(3) {
    margin-left: 50px;
    display: flex;
    align-items: center;

    > span {
      margin-left: 10px;
    }
  }
  .exportExcelBtn {
    margin: 0 50px;
  }
}

.popover-content {
  width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 20px 0;
  box-sizing: border-box;

  > div {
    width: 90%;
    height: 50px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
  }
}

.el-table {
  /deep/ .s_s {
    background: #228b22;
    color: white;
  }

  /deep/ .t_s {
    background: #6b8e23;
    color: white;
  }
}

.detailsPop {
  font-size: 16px;

  /deep/.u-transition {
    z-index: 996 !important;
  }

  .detailsList {
    width: 900px;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: #fff;
    border-bottom: 2px solid #000;

    .item-info {
      border-bottom: 2px solid #000;

      .item-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;
      }

      .item-content {
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > view {
          > view {
            padding: 2px 0;
          }
        }
      }
    }

    .header-title {
      max-height: 500px;
      overflow: auto;
      box-sizing: border-box;

      .item-ul {
        margin: 5px 0;
        border-bottom: 1px solid #8e8c8c;

        .itembox {
          //虚线
          border-top: 1px dashed #7a7a7a;
        }

        .item-li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 0;

          .paymentCode {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .paymentCode-title {
              width: 130px;
            }

            .paymentQrCode-box {
              display: flex;
              align-items: center;
            }

            .paymentQrCode {
              width: 70px;
            }

            .component-container {
              width: 40%;
            }
          }
        }

        .attachBtn {
          height: 80px;

          .attachment {
            width: 100%;
          }
        }

        .button {
          .u-button {
            margin: 0 10px;
          }
        }
      }
    }
  }
}
</style>
